import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ItemRow from './extra/ItemRow';
import Card from './extra/Card';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const PosCashier = () => {

    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [list, setList] = useState([])
    const [list_category, setListCategory] = useState([])
    const [loopItem, setLoopItem] = useState([])

    const [selectedloopItem, setSelectedLoopItem] = useState({ _id: 0 })

    const loadProduct = async (event) => {
        axios.post("http://localhost:3001/product").then((res) => {
            console.log(res.data)
            setList(res.data)
            // if (res.data.username) {
            //     sessionStorage.setItem('username', res.data.username);
            //     window.location.assign("/pos/dashboard")
            // }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const loadCategory = async (event) => {
        axios.post("http://localhost:3001/category").then((res) => {
            console.log(res.data)
            setListCategory(res.data)
            // if (res.data.username) {
            //     sessionStorage.setItem('username', res.data.username);
            //     window.location.assign("/pos/dashboard")
            // }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const deletecLoop = (id) => {
        console.log(id)
        const data = loopItem.filter((i) => {
            return i !== id;
        });

        setLoopItem(data)
        setSelectedLoopItem({ _id: 0 })
    }


    const selecteUpdate = (item) => {
        let updatedList = loopItem.filter((i) => {
            return i._id == item._id;
        });
        
        const index = loopItem.findIndex((e) => e._id == item._id);
        if (updatedList.length > 0) {
            const index = loopItem.findIndex((e) => e._id == item._id);
            loopItem[index].qty += 1;
            setLoopItem([...loopItem])
        } else {
            setLoopItem([...loopItem, { ...item, qty: 1 }])
        }
    }




    const SubTotal = loopItem?.reduce((amount, item) => {
        var split1 = (item.price).split(',');
        var split2 = (split1[0]+''+split1[1])
        amount = (split2* item.qty) + amount
        return amount;
        console.log(amount)
    }, 0);


    useEffect(() => {
        loadProduct()
        loadCategory()
    }, [])


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                POS
                            </div>
                            <div className='col-sm-12'>
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                gap: 5
                                            }}>
                                                <input type='text'
                                                    placeholder='Scan UPC'
                                                    style={{ borderRadius: 5, padding: 5, border: "1px solid #ddd", width: "100%", height: 45 }} />
                                                <div>
                                                    <button style={{ height: 45, width: 100 }}>Search</button>
                                                </div><div>
                                                    <button style={{ height: 45, width: 100 }}>Return</button>
                                                </div>
                                            </div>
                                            <div style={{ height: 500, overflowY: "auto" }} className='hidescrollCashier'>
                                                {
                                                    <ItemRow loopItem={loopItem} deletecLoop={deletecLoop} />
                                                }
                                            </div>
                                            <div>
                                                {/* <button className='btn btn-success btn-sm'>Payment</button> */}
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div></div>
                                                    <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
                                                        <span style={{ fontSize: 26, fontWeight: "bold" }}>Sub-Total</span>
                                                        <span style={{ fontSize: 20, fontWeight: "normal", textAlign: "right" }}>{SubTotal}</span>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    {/* <div style={{
                                                        width: 150,
                                                        height: 50,
                                                        border: "1px solid #ddd",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        alignSelf: "end",
                                                        backgroundColor: selectedloopItem._id != 0 ? "red" : "#ddd",
                                                        color: "#fff"
                                                    }}
                                                        onClick={() => deletecLoop(selectedloopItem)}
                                                    >
                                                        Delete
                                                    </div> */}
                                                    <div></div>
                                                    <div style={{ width: 150, height: 50, border: "1px solid #ddd", display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "end", backgroundColor: "green", color: "#fff" }}>
                                                        Payment
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-8' style={{ backgroundColor: "#fff" }}>
                                        <div style={{ paddingTop: 10, display: "flex", gap: 5 }}>
                                            {
                                                list_category.map((item, key) => {
                                                    return (
                                                        <span key={key} style={{ height: 50, padding: 5, borderRadius: 5, backgroundColor: "#ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>{item.name}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            <div>
                                                <input style={{ height: 45, width: 300 }} />
                                            </div>
                                            <div style={{ display: "flex", paddingTop: 10, paddingBlock: 10, flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", gap: 5, }}>

                                                {
                                                    list.map((item, key) => {
                                                        return (
                                                            <Card key={key} item={item} selecteUpdate={selecteUpdate}>
                                                                <span>{item.name}</span>
                                                            </Card>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default PosCashier;
