import React, { useContext, useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import PrescriptionPDFViewer from './extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from './extra/MedicationCertPDFViewer';
import AppContext from './context/AppContext';
import Login from './Login';
import LabRequestFormView from './extra/LabRequestFormView';
import ReportsRow from './extra/ReportsRow';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Reports = ({ userInfo }) => {
    const { state, dispatch } = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])

    const loadData = async () => {
        var formData = new FormData();
        formData.append("report_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }

    
    const consultaionlist = trxlist.filter((i) => {
        return i.delete == 0;
    });

    // const num = 1;
    return (
        <div className="wrapper">
            {/* Navbar */}
            <Header />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <div>
                                    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                                        <table className='table table-bordered'>
                                            <thead style={{backgroundColor:"#ddd"}}>
                                                <tr>
                                                    <th style={{maxWidth: 60}}>No.</th>
                                                    <th style={{width: 150}}>Date</th>
                                                    <th>Name</th>
                                                    <th>Medical Records</th>
                                                    <th>Payment Details</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    consultaionlist.map((i, k) => {
                                                        // console.log(i.delete_doc.lab_request_deleted, i.delete_doc.med_cert_deleted, i.delete_doc.pres_deleted, 'asdasd')

                                                        
                                                        return (
                                                            <ReportsRow key={k} k={k} i={i} loadData={loadData} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {/* {
                                            trxlist.map((i, k) => {
                                                return (
                                                    <div style={{ borderRadius: 5, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey" }}>
                                                        <div style={{ padding: 10 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5, fontWeight: 600, fontSize: 20 }}>{i.fullname}</div>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', gap: 10 }}>
                                                                {
                                                                    i.prescription.length > 0 ?
                                                                        <PrescriptionPDFViewer item={i} />
                                                                        : null
                                                                }
                                                                {
                                                                    i.med_cert != null ?
                                                                        <MedicationCertPDFViewer item={i} />
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", padding: 10, backgroundColor: "#ddd", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                            <span>Transaction No: {i.transaction_id}</span>
                                                            <span>{i.date_time}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default Reports;
