import axios from 'axios';
import React from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const FollowupBtn = (props) => {


    const onFollowup = async () => {
        console.log(props)
        // sample('asd555555asd')
        var formData = new FormData();
        formData.append("send_followup", 1);
        formData.append("patient_id", props.onclickedpatient.patient_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // swal({
            //     title: "Done!",
            //     text: "Successfully ",
            //     icon: "success",
            //     timer: 1000,
            //     button: false,
            // }).then(() => {
                props.loadpatient()
            // });
        });
    }


    return (
        <div>
            <button onClick={()=>onFollowup()} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Follow-up</button>
        </div>
    );
}

export default FollowupBtn;
