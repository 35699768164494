import React from 'react';

const ItemRow = ({loopItem, deletecLoop}) => {

    const isNumber = (n) => {
        'use strict';
        n = n.replace(/\./g, '').replace(',', '.');
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    return (
        <>
            {
                loopItem.map((item, key) => {
                    return (
                        <div key={key}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                border: "1px solid #ddd",
                                height: "auto",
                                alignItems: "left",
                                padding: 5,
                                flexShrink: 1,
                                backgroundColor: "#fff"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div>{item.name}</div>
                                    <div style={{ padding: 5 }}>
                                        <span onClick={() => deletecLoop(item)} className='pi pi-trash' style={{ color: "red" }}> </span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: 5 }}>
                                    <div style={{ width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        <div>
                                            {item.qty} x {item.price}
                                        </div>
                                        <div>{Number(item.qty) * Number(item.price.replace(",",""))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default ItemRow;
