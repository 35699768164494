import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import PrescriptionPDFViewer from '../extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from '../extra/MedicationCertPDFViewer';
import LabRequestFormView from '../extra/LabRequestFormView';

const MedicationsHistory = ({ onclickedpatient }) => {

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV, setTrxList])


    const consultaionlist = trxlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    // console.log(consultaionlist)

    return (
        <>
            {
                consultaionlist.length > 0 ?
                    <div className="hidescroll" style={{ display: "flex", flexDirection: "column", gap: 10, height: "100vh", overflow: "auto" }}>
                        {
                            consultaionlist.map((item, key) => {
                                if (item.prescription.length > 0) {
                                    return (
                                        <div key={key} style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div style={{ fontSize: 18, fontWeight: 600 }}>Consultations</div>
                                                </div>
                                                <div>
                                                    <div><i className='pi pi-calendar' /> {item.date_time}</div>
                                                </div>
                                            </div>
                                            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                                                <div style={{ paddingBottom: 5, fontWeight: "bolder" }}>Issued Medical Documents</div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                    {
                                                        item.prescription.length > 0 ?
                                                            <>
                                                                <PrescriptionPDFViewer item={item} />
                                                            </>
                                                            : null
                                                    }
                                                    {/* {
                                                    item.med_cert.length > 0 ?
                                                        <MedicationCertPDFViewer item={item} />
                                                        : null
                                                }
                                                {
                                                    (item?.lab_request?.serum == true ||
                                                        item?.lab_request?.urinalysis == true ||
                                                        item?.lab_request?.xray == true ||
                                                        item?.lab_request?.ultrasound == true ||
                                                        item?.lab_request?.ctscan == true ||
                                                        item?.lab_request?.MRI == true ||
                                                        item?.lab_request?.twelve_leads_ecg == true ||
                                                        item?.lab_request?.twoD_echo == true ||
                                                        item?.lab_request?.cardiac_stress_test == true ||
                                                        item?.lab_request?.carotid_doppler_scan == true ||
                                                        item?.lab_request?.arterial_duplex_scan == true ||
                                                        item?.lab_request?.venous_duplex_scan == true) ?
                                                        <LabRequestFormView item={item} />
                                                        : null
                                                } */}
                                                </div>
                                            </div>
                                            {/* <div style={{ padding: 10 }}>
                                                <div style={{ paddingBottom: 5, fontWeight: "bolder" }}>Payment Details</div>
                                                <div style={{ width: "100%", borderRadius: 5, boxShadow: "-1px 2px 5px grey" }}>
                                                    <div style={{ padding: 5 }}>Date: {item.date_time}</div>
                                                    <div style={{ padding: 5, backgroundColor: "#ddd", borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                                                        <span>Transaction No.: {item.transaction_id}</span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    )
                                } else { return(<></>) }
                            })
                        }
                        <br />
                    </div>
                    : null
            }
        </>
    );
}

export default MedicationsHistory;
