import React, { useContext, useState } from 'react';
// import ENv from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import { useEffect } from 'react';
// import AppContext from './context/AppContext';
import io from "socket.io-client";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PosLogin = () => {
    const history = useHistory()
    
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    
    const LoginBtn = async (event) => {
        event.preventDefault();

        axios.post("http://localhost:3001/login", { username, password }).then((res) => {
            console.log(res.data.username)
            if (res.data.username) {
                sessionStorage.setItem('username', res.data.username);
                window.location.assign("/pos/dashboard")
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }






    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            {/* <video src={require('./356955600_6510487162366595_2239991482821432230_n.mp4')} style={{ width:"100%", height:"100%", objectFit:"cover"}} autoPlay loop muted /> */}
            <div className="login-box" style={{ opacity: 0.89, position: "absolute" }}>
                <div className="login-logo">
                    {/* <img src={require('./384540628_1008238667298426_6685715244998088129_n.jpg')} style={{width:200, height:200, borderRadius:100}} /> */}
                    <div><a>POS</a></div>
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form onSubmit={LoginBtn} method="post">
                            <div className="input-group mb-3">
                                <input type="text" onChange={(e) => setUsername(e.target.value)} value={username} className="form-control" placeholder="Username" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        {/* <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label> */}
                                    </div>
                                </div>
                                {/* /.col */}
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                                {/* /.col */}
                            </div>
                        </form>
                    </div>
                    {/* /.login-card-body */}
                </div>
            </div>
        </div>

    );
}

export default PosLogin;
