import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const AddProduct = ({ loadProduct }) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState([]);

    const save = () => {


        axios.post("http://localhost:3001/add/product", {
            name: inputValue.name,
            code: "6931630201564",
            category: "Storage",
            sub_category: "M.2 NVME",
            brand: "GIGABYTE",
            cost: 0,
            price: "3,000.00",
            reorder: 5,
            warranty: "1 Year",
            status: "active",
            user_id: 1,
            tieup_prices: {
                "tieup.name": "tieup name"
            },
            variations: [{}, {}, {}],
            stock: [{}, {}, {}]
        }).then((res) => {
            console.log(res.data)
            loadProduct()
            // if (res.data.username) {
            //     sessionStorage.setItem('username', res.data.username);
            //     window.location.assign("/pos/dashboard")
            // }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>

            {/* You sure you want to remove this patient? */}
            <Dialog footer={
                <>
                    <button onClick={() => save()} style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "green", height: 40, width: 150, color: "#fff" }}>Save</button>
                </>
            } draggable={false} position='top' headerStyle={{ fontSize: 12, textAlign: "left", backgroundColor: "#fff", padding: 10 }} blockScroll={true} header={<span style={{ color: "#000", fontSize: 25 }}>Add New Product</span>} visible={open} style={{ width: '50vw' }} onHide={() => setOpen(false)}>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <div style={{ width: "50%", padding: 5 }}>
                        <div style={{ width: "100%" }}>
                            <label>Product Name</label>
                            <div style={{ width: "100%" }}>
                                <input name='name' onChange={(e)=>setInputValue({...inputValue, [e.target.name]: e.target.value})} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Product Code</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Category</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Sub-Category</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Brand</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Price</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Reorder</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                        <div>
                            <label>Warranty</label>
                            <div>
                                <input style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", outline: "none", padding: 10 }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", padding: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ border: "1px solid #ddd", borderRadius: 5, width: 250, height: 250 }}></div>
                        </div>
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <label>Variation</label>
                                <span className='pi pi-plus-circle' style={{ width: 20, height: 20, borderRadius: 100, border: "1px solid #ddd" }}></span>
                            </div>
                            <div>
                                <div style={{ width: "100%", height: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                                    <input type='text' style={{ width: 250, height: 40, border: "1px solid #ddd", borderRadius: 5 }} />
                                    <div style={{ width: 50, height: 50, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <span className='pi pi-times'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Dialog>

            <div style={{ padding: 5 }}>
                <button onClick={() => setOpen(true)} style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "green", height: 40, width: 150, color: "#fff" }}>Add Product</button>
            </div>
        </>
    );
}

export default AddProduct;
