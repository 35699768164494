import React, { useReducer, useState } from 'react'

export const initialState = {
    user_id: 0,
    username: '',
    password: '',
    prescription: [],
    complaint: '',
    diagnosis: '',
    recommendation: ''
}

const AppContext = React.createContext();


const reducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN' :
            return {
                ...state,
                user_id: Number(action.payload.user_id),
                username: action.payload.username,
            }
        case 'ADD_PRESCRIPTION' :
            return {
                ...state,
                prescription: action.payload
            }
        case 'CLEAR_PRESCRIPTION' :
            return {
                ...state,
                prescription: []
            }
        case 'CHANGE_INPUT' :
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'CLEAR_CERTIFICATE' :
            return {
                ...state,
                complaint: '',
                diagnosis: '',
                recommendation: ''
            }
        default:
            return state;
    }
}

// console.log(sessionStorage.getItem('user_id'), 'asdasd')

export const AppProvider = ({ children }) => {
    // const [counter, setCounter] = useState(0)

  
    const [state, dispatch] = useReducer(reducer, initialState); //, {setLoopItem:[], loopItem:[]}



    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {/* { counter, setCounter } */}
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;