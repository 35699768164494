import React, { useContext, useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import MedicalRecord from './component/MedicalRecord';
import PatientInfoPanel from './component/PatientInfoPanel';
import NewPatientForm from './component/NewPatientForm';
import EditPatientForm from './component/EditPatientForm';
import ConsultationForm from './component/ConsultationForm';
import AddVitalForm from './component/AddVitalForm';
import AppContext from './context/AppContext';
import Login from './Login';
import io from "socket.io-client";
import PatientRow from './extra/PatientRow';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Patient = ({ userInfo, newtransaction }) => {

    // const socket = io.connect(ENV.socketAPI);

    const position = sessionStorage.getItem('position');
    const { state, dispatch } = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed sidebar-collapse';


    const [newPatientForm, setNewPatientForm] = useState(false)
    const [editPersonalInfo, setEditPersonalInfo] = useState(false)
    const [startConsultionForm, setStartConsultionForm] = useState(false)
    const [addvitalForm, setAddVitalForm] = useState(false)


    const [inputform, setInputForm] = useState({})

    const [patientlist, setPatientList] = useState([])
    const [onclickedpatient, onClickedPatient] = useState(null)
    const [search, setSearch] = useState('')


    const [onclickedbutton, onClickedButton] = useState('PatientInfoPanel')
    const [resizepanel, setResizePanel] = useState('col-sm-4')

    useMemo(async () => {

        var formData = new FormData();
        formData.append("patient_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setPatientList(res.data)
        })
    }, [ENV])

    const loadpatient = async () => {
        var formData = new FormData();
        formData.append("patient_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setPatientList(res.data)
        })
    }

    const searchData = (data, search, status) => {
        // let data = sales;
        if (search) {
            data = data?.filter((i) =>
                i?.fullname.toLowerCase().includes(search.trim().toLowerCase()) || i?.firstname.toLowerCase().includes(search.trim().toLowerCase()))
        }
        return data
    }


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            {
                position != 'cashier' ?
                    <Navbar userInfo={userInfo} />
                    : null
            }
            {/* Content Wrapper. Contains page content */}
            <div className={position != 'cashier' ? "content-wrapper" : null}>
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row" style={{ display: "none" }}>
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                                    <button style={{ borderRadius: 5, width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        <div className="row" style={{height: "90vh"}}>
                            <div className={resizepanel == 'col-sm-3' ? 'col-sm-3' : 'col-sm-3'} style={{ borderRight: "2px solid #ddd", padding: 0 }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    {/* <div style={{ padding: 5, fontSize: 30, fontWeight: 600 }}>Your Patients</div> */}
                                    {
                                        window.innerWidth == 1080 ? 
                                        <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", gap: 10 }}>
                                            {
                                                onclickedbutton == 'NewPatientForm' ?
                                                    <>
                                                        <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                                    </>
                                                    :
                                                    <>
                                                        {/* {
                                                            window.innerWidth == 1080 ?
                                                                <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                                                :
                                                                <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey", fontSize: resizepanel == 'col-sm-2' ? 12 : null }}>New Patient</button>
                                                        } */}
                                                        <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                                    </>
                                            }
                                            <div style={{border: "1px solid #ddd", borderRadius:5, display:"flex", flexDirection:"row", width:"100%"}}>
                                                <input onChange={(e) => setSearch(e.target.value)} style={{ flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border:"none",  }} placeholder='Type a patient name' />
                                                <div style={{width: 50, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                    <span className='pi pi-search' ></span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ paddingTop: 0, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                                            <div style={{border: "1px solid #ddd", borderRadius:5, display:"flex", flexDirection:"row", width:"90%"}}>
                                                <input onChange={(e) => setSearch(e.target.value)} style={{ flexGrow: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, height: 35, padding: 5, border:"none",  }} placeholder='Type a patient name' />
                                                <div style={{width: 50, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                    <span className='pi pi-search' ></span>
                                                </div>
                                            </div>
                                            {
                                                onclickedbutton == 'NewPatientForm' ?
                                                    <>
                                                        <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                                    </>
                                                    :
                                                    <>
                                                        {/* {
                                                            window.innerWidth == 1080 ?
                                                                <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                                                :
                                                                <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey", fontSize: resizepanel == 'col-sm-2' ? 12 : null }}>New Patient</button>
                                                        } */}
                                                        <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                                    </>
                                            }
                                        </div>
                                    }

                                    {/* {
                                        position != 'cashier' ?
                                        <div style={{ paddingTop: 0, paddingBottom: 10, paddingRight: 5, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                                                <input onChange={(e) => setSearch(e.target.value)} style={{ border: "1px solid #ddd", flexGrow: 1, borderRadius: 5, height: 35, padding: 5 }} placeholder='Type a patient name' />
                                                {
                                                    onclickedbutton == 'NewPatientForm' ?
                                                        <>
                                                            <button onClick={() => onClickedButton('PatientInfoPanel')} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                window.innerWidth == 1080 ?
                                                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>New</button>
                                                                    :
                                                                    <button onClick={() => { onClickedButton('NewPatientForm'); onClickedPatient(null) }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey", fontSize: resizepanel == 'col-sm-2' ? 12 : null }}>New Patient</button>
                                                            }
                                                        </>
                                                }
                                            </div>
                                            :
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 5, gap: 5, alignItems: "center" }}>
                                                <span style={{fontWeight: 600}}>Search:</span>
                                                <input onChange={(e) => setSearch(e.target.value)} style={{ border: "1px solid #ddd", flexGrow: 1, borderRadius: 5, height: 35, padding: 5, width: 300 }} placeholder='Type a patient name' />
                                            </div>
                                    } */}

                                    <div className='hidescroll' style={{ display: "flex", flexDirection: "column", gap: 2, overflowX: "auto", height: "90vh" }}>
                                        {
                                            searchData(patientlist, search, null).map((item, key) => {
                                                return (
                                                    <PatientRow key={key} onclickedpatient={onclickedpatient} item={item} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} />
                                                )
                                                // return (
                                                //     <div key={key} onClick={() => { onclickedpatient?.patient_id == item.patient_id ? onClickedPatient(item) : onClickedPatient(item); onClickedButton('PatientInfoPanel'); }} style={{ display: "flex", cursor: onclickedpatient?.patient_id == item.patient_id ? null : "pointer", flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottom: "1px solid #ddd", backgroundColor: onclickedpatient?.patient_id == item.patient_id ? "#43bea0" : "#fff" }}>
                                                //         <div style={{ flexGrow: 1, maxHeight: 100, backgroundColor: onclickedpatient?.patient_id == item.patient_id ? "#43bea0" : null, color: onclickedpatient?.patient_id == item.patient_id ? "#fff" : null }}>
                                                //             <div style={{ paddingLeft: 5 }}>
                                                //                 <div style={{ fontWeight: 600 }}>{item.fullname}</div>
                                                //                 <div style={{ fontSize: 12, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                                                //                     <span>{item.gender == 'male' ? 'Male' : 'Female'}</span>
                                                //                     <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                                                //                     <span>{item.age} years old</span>
                                                //                 </div>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                // )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={resizepanel == 'col-sm-9' ? 'col-sm-9' : 'col-sm-9'} style={{ padding: 0, height: "90vh" }}>
                                {
                                    onclickedpatient ?
                                        <>
                                            {
                                                onclickedbutton == 'PatientInfoPanel' ?
                                                    <PatientInfoPanel setAddVitalForm={setAddVitalForm} onclickedpatient={onclickedpatient} onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} setResizePanel={setResizePanel} resizepanel={resizepanel} loadpatient={loadpatient} />
                                                    // patientlist, setPatientList
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'EditPatientForm' ?
                                                    <EditPatientForm onclickedpatient={onclickedpatient} onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} loadpatient={loadpatient} />
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'ConsultationForm' ?
                                                    <ConsultationForm onclickedpatient={onclickedpatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} />
                                                    : null
                                            }
                                            {
                                                onclickedbutton == 'AddVitalForm' ?
                                                    <AddVitalForm onclickedpatient={onclickedpatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} />
                                                    : null
                                            }
                                            {/* {
                                                editPersonalInfo == true || startConsultionForm == true || addvitalForm == true ?
                                                    null
                                                    :
                                                    <PatientInfoPanel setAddVitalForm={setAddVitalForm} onclickedpatient={onclickedpatient} setEditPersonalInfo={setEditPersonalInfo} setStartConsultionForm={setStartConsultionForm} />
                                            }
                                            {
                                                editPersonalInfo ?
                                                    <EditPatientForm onclickedpatient={onclickedpatient} setEditPersonalInfo={setEditPersonalInfo} />
                                                    : null
                                            }
                                            {
                                                startConsultionForm ?
                                                    <ConsultationForm onclickedpatient={onclickedpatient} setStartConsultionForm={setStartConsultionForm} />
                                                    : null
                                            }
                                            {
                                                addvitalForm ?
                                                    <AddVitalForm onclickedpatient={onclickedpatient} setStartConsultionForm={setStartConsultionForm} />
                                                    : null
                                            } */}
                                        </>
                                        :
                                        <>
                                            {
                                                onclickedbutton == 'NewPatientForm' ?
                                                    <NewPatientForm onClickedPatient={onClickedPatient} onclickedbutton={onclickedbutton} onClickedButton={onClickedButton} loadpatient={loadpatient} />
                                                    :
                                                    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={require('./component/doctor-symbol-universal-png-2.png')} style={{width:"50%", height:"50%"}} />
                                                        <span style={{ textShadow: "0 0 3px #000, 0 0 5px #000", fontSize: 30, color: "#fff" }}>No Selected Patient!</span>
                                                        {/* <span style={{ textShadow: "0 0 3px #000, 0 0 5px #000", fontSize: 30, color: "#fff" }}>{time}</span> */}
                                                        {/* <span>
                                                            {
                                                                JSON.stringify(newtransaction)
                                                            }
                                                        </span> */}
                                                    </div>

                                            }
                                        </>
                                }
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default Patient;
