import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import AddProduct from './extra/Add_Product';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const PosProduct = () => {

    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [list, setList] = useState([])

    const loadProduct = async (event) => {
        axios.post("http://localhost:3001/product").then((res) => {
            console.log(res.data.length)
            setList(res.data)
            // if (res.data.username) {
            //     sessionStorage.setItem('username', res.data.username);
            //     window.location.assign("/pos/dashboard")
            // }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        loadProduct()
    }, [])


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <AddProduct loadProduct={loadProduct} />
                            </div>
                            <div className='col-sm-12'>
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Code</th>
                                            <th>Product Name</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((item, key)=>{
                                                return (
                                                    <tr key={key}>
                                                        <td></td>
                                                        <td>{item.code}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.price}</td>
                                                        <td style={{display:"flex", flexDirection:"row", gap:5}}>
                                                            <a className={"btn btn-success btn-xs"}>View</a>
                                                            <a className={"btn btn-info btn-xs"}>Edit</a>
                                                            <a className={"btn btn-danger btn-xs"}>Delete</a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default PosProduct;
