import React, { useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import ENV from '../ENV';
import { useReactToPrint } from 'react-to-print';

const LabRequestFormView = ({ item, width = 150, view = false }) => {
    // console.log(item[0]?.lab_request)


    const [open, setOpen] = useState(false);


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <>
            <Sidebar visible={open} header={<>Change Password</>} position="right" style={{ minHeight: 400, minWidth: "70%", borderRadius: 0 }} onHide={() => setOpen(false)}>

                {/* <button onClick={handlePrint}>Print this out!</button>
                <div ref={componentRef} style={{ padding: 15 }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: 250, height: 250, backgroundColor: null }}>
                            <img src='http://localhost/tubillara_clinic/image/46964720_931525337041328_7708105959918272512_n.jpg' style={{ width: 250 }} />
                        </div>
                        <div style={{ backgroundColor: null, flexGrow: 1, height: 250, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ fontSize: 40, fontWeight: 600 }}>Jan Zoilo B. Tubillara M.D.</div>
                            <div style={{ fontSize: 25, fontWeight: 600 }}>Adult Diseases</div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "100%", padding: 10 }}>
                                <div style={{ display: "flex", flexDirection: "column", fontSize: 18, width: "50%" }}>
                                    <span>Room 4M09</span>
                                    <span>Simplicio Palanca Medical Arts Building</span>
                                    <span>Adventist Medical Center - Bacolod </span>
                                    <span>10:00 a.m. - 3:00 p.m. </span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", fontSize: 18, width: "50%" }}>
                                    <span>Bacolod Adventist Medical Center</span>
                                    <span>Door #4 Lower Ground Hualde Space</span>
                                    <span>Circumferential Road, Alijis</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignSelf: "flex-start", paddingLeft: 10 }}>
                                <span style={{ fontSize: 18 }}>For appointments you may contact: 09685608340</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <div style={{ width: "100%", border: "3px solid #000" }}></div>
                            <div style={{ width: "100%", border: "3px solid #000" }}></div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", padding: 10 }}>
                            <span style={{ fontSize: 30, fontWeight: 600 }}>LABORATORY REQUEST FORM</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ fontSize: 18 }}>Patient Name: <b>{item[0].fullname}</b></span>
                            <span style={{ fontSize: 18 }}>Contact Number: <b>{item[0].contact}</b></span>
                            <span style={{ fontSize: 18 }}>Date of Birth: <b>{item[0].birthday}</b></span>
                            <span style={{ fontSize: 18 }}>Gender: <b style={{ textTransform: "capitalize" }}>{item[0].gender}</b></span>
                        </div>

                    </div>
                    <div>
                        <div style={{ border: "1px solid #fff" }}>
                            <span style={{ fontSize: 18, fontWeight: 600 }}>Examination requested:</span>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 10 }}>
                                {
                                    (item[0]?.lab_request?.cbc === true) || (item[0]?.lab_request?.platelet === true) || (item[0]?.lab_request?.blood_type === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Laboratories: </span>
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.cbc === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>CBC</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.platelet === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>Platelet</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.blood_type === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>Blood Type</span>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null

                                }
                                {
                                    (item[0]?.lab_request?.serum === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Serum</span>
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.serum_creatinine === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>Creatinine</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_bun === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>BUN</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_uric_acid === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>Uric Acid</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_alt === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>ALT</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ast === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>AST</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_phos === true) ?
                                                        <span style={{ paddingLeft: 5, paddingRight: 5, border: "1px solid #ddd" }}>PHOS</span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_mg_plus2 === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 55, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>MG</span>
                                                            <span>++</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ica_plus2 === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 55, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>ICA</span>
                                                            <span>++</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_k_plus === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 50, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>K</span><span>+</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_na_plus === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 50, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>NA</span><span>+</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_lipitprofile === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Lipit Profile</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_fbs === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 50, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>FBS</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_rbs === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", width: 50, paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>RBS</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_hba1c === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>HBA1C</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_appt === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>APPT</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_protime === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Protime</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_albumin === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Albumin</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_troponin_i === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Troponin I</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_abg === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>ABG</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_alkaline_phosphatase === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Alkaline Phosphatase</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_amylase === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Amylase</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_lipase === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Lipase</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_hbsAg === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>HBSAG</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_anti_hav === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Anti-HAV</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_anti_hcv === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Anti-HCV</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_anti_hbs_titer_quantitative === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Anti-HBS Titer Quantitative</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_hiv === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>HIV</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_tsh === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>TSH</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ft3 === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>FT3</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ft4 === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>FT4</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_anti_tpo_antibodies === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Anti-TPO Antibodies</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_esr === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>ESR</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_crp === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>CRP</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_procalcitonin === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Procalcitonin</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ferritin === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>Ferritin</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ldh === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>LDH</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_ana_quantitative === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>ANA Quantitative</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_dsdna === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>DSDNA</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_c3_quantitative === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>C3 Quantitative</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_aso_titer === true) ?
                                                        <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                                                            <span>ASO Titer</span>
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.serum_bilirubin === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Bilirubin
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.serum_bilirubin_total === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Total
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.serum_bilirubin_direct === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Direct
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.serum_bilirubin_indirect === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Indirect
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.xray === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>X-Ray</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.xray_chest === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>Chest</span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.xray_chest_pa === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>PA</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_chest_ap === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>AP</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_chest_lateral === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>Lateral</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_chest_apicolordotic === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>Apicolordotic</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.xray_abdomen === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>Abdomen</span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.xray_abdomen_supine === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>Supine</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_abdomen_upright === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>Upright</span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.xray_spine === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>Spine</span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.xray_spine_cervical === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Cervical
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_spine_cervico_thoracic === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Cervico Thoracic
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_spine_thoracic === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Thoracic
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_spine_thoracolumbar === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Thoracolumba
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.xray_spine_lumbar === true) ?
                                                                        <div>

                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Lumbar
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.xray_foot === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Foot
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.xray_foot_apo === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Apo
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.ultrasound === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Ultrasound</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.ultrasound_abdomen === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Abdomen
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.ultrasound_abdomen_whole === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Whole
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.ultrasound_abdomen_lower === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Lower
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.ultrasound_abdomen_upper === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Upper
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ultrasound_kub === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                KUB
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ultrasound_kubp === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                KUBP
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ultrasound_hepatobiliary === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Hepatobiliary
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ultrasound_thyroid === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Thyroid
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ultrasound_breast === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Breast
                                                            </span>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.ctscan === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>CT-Scan</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.ctscan_chest === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Chest
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.ctscan_chest_plain === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Plain
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.ctscan_chest_contrast === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Contrast
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ctscan_cranial === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Cranial
                                                            </span>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {
                                                                    (item[0]?.lab_request?.ctscan_cranial_plain === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Plain
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.ctscan_cranial_contrast === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Contrast
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (item[0]?.lab_request?.ctscan_cranial_angiography === true) ?
                                                                        <div>
                                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                                Angiography
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ctscan_abdomen === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Abdomen
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ctscan_plain === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Plain
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ctscan_triphasic_contrast === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Triphasic Contrast
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.ctscan_triple_contrast === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: 600 }}>
                                                                Triple Contrast
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.MRI === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>MRI</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>
                                                {
                                                    (item[0]?.lab_request?.MRI_Cranial === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                Cranial
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.MRI_spinal === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                Spinal
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (item[0]?.lab_request?.MRI_abdominal === true) ?
                                                        <div>
                                                            <span style={{ paddingLeft: 5, position: "relative", paddingRight: 5, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                Abdominal
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.venous_duplex_scan === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Venous Duplex Scan</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>

                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.arterial_duplex_scan === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Arterial Duplex Scan</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>

                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    (item[0]?.lab_request?.carotid_doppler_scan === true) ?
                                        <div style={{ borderRadius: 5, border: " 1px solid #ddd", padding: 5 }}>
                                            <span style={{ fontWeight: "bolder" }}>Carotid Doppler Scan</span>
                                            <div style={{ display: "flex", flexDirection: "row", gap: 0 }}>

                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br /> */}
                <iframe src={ENV.DOMAIN + "labrequestform.php?trans_id=" + item.transaction_id} style={{ width: "100%", height: "100%" }} />
            </Sidebar>

            <div onClick={() => view === true ? null : setOpen(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: view === true ? null : "pointer", padding: 10, width: width, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                <span>Lab Request</span>
                {
                    view === true ?
                    <span onClick={() => setOpen(true)} style={{color: "green", cursor: "pointer"}}>View</span>
                    :null
                }
            </div>
        </>
    );
}

export default LabRequestFormView;
