import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment/moment';
import useLongPress from '../extra/useLongPress';
import validator from 'validator'
import ChartingTable from './ChartingTable';
import swal from 'sweetalert';

const ChartingForm = ({ onclickedpatient, onConsultationButton, medCertData, index, prescriptiondata }) => {

    const inputRef = useRef();
    const fileRef = useRef();


    const scrollEndRef = useRef(null)

    const scrollToBottom = () => {
        scrollEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const [inputValue, setInputValue] = useState({
        "id": "",
        "patient_id": onclickedpatient.patient_id,
        "focus": "",
        "progress": "",
        "time": "",
        "date_time": ""
    });
    const [chartingList, setChartingList] = useState([]);
    const [selected, setSelected] = useState();

    const [addRow, setAddRow] = useState([])
    const [choosefiles, setChooseFiles] = useState()



    useMemo(async () => {
        var formData = new FormData();
        formData.append("charting_list", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setChartingList(res.data)
        })
    }, [ENV, onclickedpatient])

    const loadData = async () => {
        var formData = new FormData();
        formData.append("charting_list", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setChartingList(res.data)
        })
    }

    const save = async () => {
        var formData = new FormData();
        formData.append("add_charting", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        formData.append("focus", inputValue.focus); //onclickedpatient.patient_id
        formData.append("progress", inputValue.progress); //onclickedpatient.patient_id
        formData.append("data", JSON.stringify(addRow)); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            // setTrxList(res.data)
            // console.log(JSON.stringify(inputValue))
            setInputValue({ focus: '', progress: '' })
            loadData()
            setAddRow([])
            setTimeout(() => scrollToBottom(), 1000)
        })
    }


    const onPress = async (e) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        }
        else if (e.which == 13) {

            if (selected) {
                var formData = new FormData();
                formData.append("update_charting", 1); //onclickedpatient.patient_id
                formData.append("id", selected.id); //onclickedpatient.patient_id
                formData.append("focus", inputValue.focus); //onclickedpatient.patient_id
                formData.append("progress", inputValue.progress); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
                setSelected()
            } else {
                var formData = new FormData();
                formData.append("add_charting", 1); //onclickedpatient.patient_id
                formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
                formData.append("focus", inputValue.focus); //onclickedpatient.patient_id
                formData.append("progress", inputValue.progress); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
            }



            // setChartingList([...chartingList, { focus: inputValue.focus, progress: inputValue.progress }])
        }
    }

    const onPressUpdate = async (e, i) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        } else
            if (e.which == 13) {
                console.log(e.target.name, e.target.value, i)
                // return;
                var formData = new FormData();
                formData.append("update_charting_row", 1); //onclickedpatient.patient_id
                formData.append("id", i); //onclickedpatient.patient_id
                formData.append("field", e.target.name); //onclickedpatient.patient_id
                formData.append("value", e.target.value); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
                setSelected()
            }
    }

    const remove = async (item) => {
        var formData = new FormData();
        formData.append("remove_charting", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        formData.append("id", item?.id); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(e)
            setInputValue({ focus: '', progress: '' })
            loadData()
            setSelected()
        })
    }

    const onPressInputField = async (e) => {



        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        }
        else if (e.which == 13) {
            setAddRow([...addRow, { patient_id: onclickedpatient.patient_id, focus: inputValue.focus, progress: inputValue.progress }])
            setInputValue({ progress: '' })
            inputRef.current.focus();
            inputRef.current.value = '';
        }
    }

    const removerow = async (item) => {

        const data = addRow.filter((i) => i != item);
        // console.log(addRow)
        setAddRow(data)
    }

    // console.log(chartingList)



    const groupedData = chartingList?.reduce((groups, item) => {
        const { date, date_time, time } = item;
        // return date_time
        // console.log(product_id)
        // if (product_id == 0) {
        //     if (!groups[product_id, product_name]) {
        //         groups[product_id, product_name] = [];
        //     }
        //     groups[product_id, product_name].push(item);
        // } else {
        if (!groups[moment(date).format('hh:mm:ss')]) {
            groups[moment(date).format('hh:mm:ss')] = [];
        }
        groups[moment(date).format('hh:mm:ss')].push(item);
        // }
        return groups;
    }, {});


    const [doubleclickin, setDoubleClickinRow] = useState(null)
    const [edittext, setEditText] = useState('')
    const [singleClick, setSingleClick] = useState('')



    const onLongPress = (e) => {
        console.log('longpress is triggered ' + e);
    };

    const onClick = (e) => {
        console.log('click is triggered ' + e)
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    const handleClick = (e, items) => {
        switch (e.detail) {
            case 1:
                // console.log("click");
                setDoubleClickinRow({})
                setSingleClick({})
                break;
            case 2:
                // console.log("double click" + JSON.stringify(items));
                setDoubleClickinRow(items)
                setEditText(items)
                setSingleClick(items)
                // e.target.select()
                break;
            case 3:
                // console.log("triple click");
                break;
        }
    };


    const trimSpace = (str) => {
        return str.replace(/^[ \t]+|[ \t]+$/g, '')
    }



    const packFiles = (files) => {
        const data = new FormData()
        {
            [...files].forEach((file, i) => {
                data.append(`file-${i}`, file, file.name)
            })
        }
        return data
    }

    // const handleUploadClick = () => {
    //     if (files.length) {
    //         const data = packFiles(files)
    //         uploadFiles(data)
    //     }
    // }

    // const renderFileList = () => (<ol>
    //     {[...files].map((f, i) => (
    //         <li key={i}>{f.name} - {f.type}</li>
    //     ))}
    // </ol>)

    // const getButtonStatusText = () => (
    //     (status === STATUS_IDLE) ? 
    //         'Send to server' : 
    //         <img src = "./load.svg" />
    // )

    // const data = new FormData();
    // for (let i = 0; i < choosefiles.length; i++) {
    //     data.append(`image[${i}]`, choosefiles[0])
    // }
    // console.log(data)

    const changeHandlerImage = async (event) => {
        let file = event.target.files[0];


        const formData = new FormData();
        formData.append("charting_upload_image", 1);
        formData.append("patient_id", onclickedpatient.patient_id);
        // formData.append("base64", e.target.result);
        // formData.append("name", files[0].name);
        // formData.append("ext", files[0].name.split(".")[1]);


        // for (let i = 0; i < [...choosefiles].length; i++) {
        //     console.log([...choosefiles][i])
        //     formData.append('file', [...choosefiles][0])
        // }

        // for (let i; i < choosefiles.length; i++) {
        //     let file = choosefiles[i];
        //     formData.append('productPhotos[' + i + ']', file);
        // }
        let productimages = [];

        for (let i = 0; i < event.target.files.length; i++) {
            productimages.push(event.target.files[i]);
            formData.append('file[]', event.target.files[i])
        }

        // var post_data = new FormData();
        // if (file.length > 0) {
        //     file.forEach(function (i, v) {
        //         formData.append('my_file[]', file);
        //     });
        // }

        // formData.append('file[]', event.target.files)
        // formData.append('file', event.target.files[0])

        // console.log([...choosefiles].map((file, i)=>file)[0])

        // console.log([...choosefiles].length)
        // console.log(productimages)
        // console.log(...productimages[0])


        // return
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data)
            swal({
                title: "Done!",
                text: "Successfully Uploaded",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                loadData()
                setTimeout(() => scrollToBottom(), 1000)
                fileRef.current.value = ''
            });
        }).catch((err) => {

        });


        // fetch(ENV.DOMAIN + 'process.php', { method: "POST", body: formData })
        //     .then((res) => res.json())
        //     .then((data) => console.log(data))
        //     .catch((err) => console.log(err))





        return
        if (event.target.files && event.target.files[0]) {
            const files = event.target.files;
            let reader = new FileReader();
            // console.log(packFiles(files))

            // console.log(event.target.files[2])

            // const chosenFiles = Array.prototype.slice.call(event.target.files)
            // console.log(chosenFiles)
            // return;
            let formData = new FormData();
            formData.append("charting_upload_image", 1);
            formData.append("patient_id", onclickedpatient.patient_id);
            // formData.append("base64", e.target.result);
            // formData.append("name", files[0].name);
            // formData.append("ext", files[0].name.split(".")[1]);

            const data = []
            for (let i = 0; i < files.length; i++) {
                // console.log(files[i])
                data[i] = files[i]
                // formData.append(`file[${i}]`, files[i])
            }
            console.log(data)
            // [...files].forEach((file, i) => formData.append(`file`, file[i]));
            // console.log(...files.name)
            // formData.append("files", files[0]);
            // return;
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData
            }).then((res) => {
                console.log(res.data)
                // swal({
                //     title: "Done!",
                //     text: "Successfully Uploaded",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                //     loadData()
                //     setTimeout(() => scrollToBottom(), 1000)
                //     fileRef.current.value = ''
                // });
            }).catch((err) => {

            });











            // reader.readAsDataURL(files); 
            // reader.onload = async (e) => {
            //     console.log(e.target.result)
            // }  
            // reader.readAsDataURL(files[0]);
            // if (files[0].name.split(".")[1] == 'png' || files[0].name.split(".")[1] == 'JPG' ||  files[0].name.split(".")[1] == 'PNG' || files[0].name.split(".")[1] == 'jpg') {
            //     reader.onload = async (e) => {
            //         console.log(e.target.result)

            //         return;
            //         var formData = new FormData();
            //         formData.append("charting_upload_image", 1);
            //         formData.append("patient_id", onclickedpatient.patient_id);
            //         formData.append("base64", e.target.result);
            //         formData.append("name", files[0].name);
            //         formData.append("ext", files[0].name.split(".")[1]);
            //         await axios({
            //             method: "post",
            //             url: ENV.DOMAIN + 'process.php',
            //             data: formData,
            //         }).then((res) => {
            //             swal({
            //                 title: "Done!",
            //                 text: "Successfully Uploaded",
            //                 icon: "success",
            //                 timer: 1000,
            //                 button: false,
            //             }).then(() => {
            //                 loadData()
            //                 setTimeout(() => scrollToBottom(), 1000)
            //                 fileRef.current.value = ''
            //             });
            //         }).catch((err) => {

            //         });
            //     }
            // }
        }
    };


    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "relative" }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 0 }}>

                    <div>
                        {
                            window.innerWidth == 1080 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <div style={{ width: 180, padding: 5, fontSize: 12 }}></div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                                <textarea ref={inputRef} name='focus' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.focus} onKeyPress={(e) => onPressInputField(e)} style={{ width: 200 }} />
                                                <textarea name='progress' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: 220 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            window.innerWidth == 1366 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <div style={{ width: 255, padding: 5, fontSize: 12 }}></div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                                <textarea ref={inputRef} name='focus' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.focus} onKeyPress={(e) => onPressInputField(e)} style={{ width: 275 }} />
                                                <textarea name='progress' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            window.innerWidth == 1536 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <div style={{ width: 225, padding: 5, fontSize: 12 }}></div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                                <textarea ref={inputRef} name='focus' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.focus} onKeyPress={(e) => onPressInputField(e)} style={{ width: 150 }} />
                                                <textarea name='progress' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: 450 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            window.innerWidth == 1920 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)', paddingRight: 30 }}>
                                    <div style={{ width: 250, padding: 5, fontSize: 12 }}></div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                                <textarea ref={inputRef} name='focus' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.focus} onKeyPress={(e) => onPressInputField(e)} style={{ width: 265 }} />
                                                <textarea name='progress' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            window.innerWidth == 2560 ?
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <div style={{ width: 250, padding: 5, fontSize: 12 }}></div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                                <textarea ref={inputRef} name='focus' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.focus} onKeyPress={(e) => onPressInputField(e)} style={{ width: 265 }} />
                                                <textarea name='progress' onChange={(e) => setInputValue({ ...inputValue, patient_id: onclickedpatient.patient_id, [e.target.name]: e.target.value })} value={inputValue.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, backgroundColor: 'rgb(255, 255, 255)', paddingRight: 30 }}>
                            <div style={{ width: 74, padding: 5, fontSize: 12 }}></div>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5, alignItems: 'center' }}>
                                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 2, padding: 0 }}>
                                        {/* <button onClick={() => save()} style={{ borderRadius: 5, height: 30, backgroundColor: 'green', fontSize: 12, border: 'none', color: 'rgb(255, 255, 255)', fontWeight: 600, width: "20%" }}>Save</button> */}
                                    </div>
                                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2, padding: 20 }}>
                                        {/* <input type='button' onClick={(e)=>changeHandlerImage(e)} value={"Upload"} style={{ borderRadius: 5, height: 30, width: 100, backgroundColor: '#5f6cb4', fontSize: 12, border: 'none', color: 'rgb(255, 255, 255)', fontWeight: 600, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }} /> */}
                                        <input ref={fileRef} name='file' type="file" onChange={(e) => changeHandlerImage(e)} multiple />
                                        {/* <button onClick={() => changeHandlerImage()} style={{ borderRadius: 5, height: 30, width: 100, backgroundColor: 'green', fontSize: 12, border: 'none', color: 'rgb(255, 255, 255)', fontWeight: 600, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>
                                            Upload
                                        </button> */}
                                        <button onClick={() => save()} style={{ borderRadius: 5, height: 30, width: 100, backgroundColor: 'green', fontSize: 12, border: 'none', color: 'rgb(255, 255, 255)', fontWeight: 600, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>Save</button>
                                        {/* <button >Save</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>

{
    addRow.filter((i)=>i.patient_id === onclickedpatient.patient_id).reverse().map((item, key) => {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 0, backgroundColor: 'rgb(255, 255, 255)' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 0, alignItems: 'flex-start' }}>
                        <div style={{ width: 185, padding: 5, fontSize: 12 }}></div>
                        <div style={{ padding: 5, display: "flex", flexDirection: "row", gap: 0 }}>
                            <button onClick={() => removerow(item)} style={{ borderRadius: 5, backgroundColor: 'red', fontSize: 12, border: 'none', color: 'rgb(255, 255, 255)', fontWeight: 600 }}>Remove</button>
                        </div>
                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 0, padding: 0 }}>
                            <div style={{ width: 200, border: "1px solid #ddd", wordWrap: "break-word", padding: 2, fontSize: 12 }}>
                                {
                                    item.focus.split("\n").map((item) => {
                                        return (
                                            <>
                                                {item}
                                                < br />
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ flexGrow: 1, border: "1px solid #ddd", padding: 2, fontSize: 12 }}>
                                {
                                    item.progress.split("\n").map((item) => {
                                        return (
                                            <>
                                                {item}
                                                < br />
                                            </>
                                        )
                                    })
                                }
                            </div>
                            {/* <textarea value={item.progress} onKeyPress={(e) => onPressInputField(e)} style={{ width: "100%" }} /> */}
                        </div>
                    </div>
                </div>
            </div>
            // <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent:"center", borderBottom: "1px solid #ddd", backgroundColor: "#fff" }}>
            //     <div style={{width:"30%"}}>
            //         <input value={item.focus} style={{width:"100%"}} />
            //     </div>
            //     <div style={{width:"40%"}}>
            //         <input value={item.progress} onKeyPress={(e) => onPressInputField(e)} style={{width:"100%"}} />
            //     </div>
            //     <button onClick={()=>removerow(item)}>Remove</button>
            // </div>
        )
    })
}
                        <br />
                    </div>
                </div>
            </div>
            <ChartingTable scrollEndRef={scrollEndRef} chartingList={chartingList} loadData={loadData} onclickedpatient={onclickedpatient} onConsultationButton={onConsultationButton} medCertData={medCertData} index={index} prescriptiondata={prescriptiondata} />
        </div>
    );
}

export default ChartingForm;
