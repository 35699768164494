import React from 'react';
import LabRequestFormView from './LabRequestFormView';
import PrescriptionPDFViewer from './PrescriptionPDFViewer';
import MedicationCertPDFViewer from './MedicationCertPDFViewer';
import ReportTableButton from './ReportTableButton';

const ReportsRow = ({k, i, loadData}) => {
    
    return (
        <tr>
            <td>{k+1}</td>
            <td>{i.date_time}</td>
            <td>
                <b>{i.fullname}</b>
            </td>
            <td>
                <div style={{display:"flex", flexDirection:"column", gap:5}}>
                {/* i.delete_doc.lab_request_deleted === true && i.delete_doc.med_cert_deleted === true && i.delete_doc.pres_deleted === true */}
                {
                    i.prescription.length > 0 ?
                        <PrescriptionPDFViewer item={i} />
                        : null
                }
                {
                    i.med_cert.length > 0 ?
                        <MedicationCertPDFViewer item={i} />
                        : null
                }
                {
                    Object.keys(i.lab_request).length>0 ?
                            <LabRequestFormView item={i} />
                        : null
                }
                </div>
            </td>
            <td>
                {
                    i.method !== 'Input Amount' ?
                        <b>{i.method}</b>
                    :
                    <>
                    {
                        i.status === 'paid' ?
                        <>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                                <span style={{color:"green", fontWeight: 600}}>Paid</span>
                                <span style={{color:"#000", fontWeight: 600}}>{'P ' + i.amount}</span>
                            </div>
                            {/* <div>
                                <span>Amount: <b>{'P ' + i.amount}</b></span>
                            </div> */}
                            {/* <div>
                                <span>Tendered: <b>{'P.' + i.tendered}</b></span>
                            </div>
                            <div>
                                <span>Changed: <b>{'P.' + i.change}</b></span>
                            </div> */}
                        </>
                        :
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                            <span style={{color:"#c35e18", fontWeight: 600}}>Pending Payment</span>
                            <span style={{color:"#000", fontWeight: 600}}>{'P ' + i.amount}</span>
                        </div>
                    }
                    </>
                }
            </td>
            <td>
                <ReportTableButton i={i} loadData={loadData} />
            </td>
        </tr>
    )
}

export default ReportsRow;
