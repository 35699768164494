import React, { useContext, useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import ENV from './ENV';
import axios from 'axios';


import { ProgressSpinner } from 'primereact/progressspinner';
import PreSignUpListBtn from './component/dashboard/PreSignUpListBtn';
import Countdown from './extra/Countdown';
import { Skeleton } from 'primereact/skeleton';
import PhoneNumberSetup from './extra/PhoneNumberSetup';
import ChristmasBanner from './extra/ChristmasBanner';
import PrescriptionPDFViewer from './extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from './extra/MedicationCertPDFViewer';
import AppContext from './context/AppContext';
import Login from './Login';
import LabRequestFormView from './extra/LabRequestFormView';
// import {Html5QrcodeScanner} from 'https://unpkg.com/html5-qrcode@2.3.8/html5-qrcode.min.js';

const Transactions = ({ userInfo }) => {
    const {state, dispatch} = useContext(AppContext);
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])
    

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                // window.innerWidth <= 768 ?
                //     <>
                //         <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                //             <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                //                 <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                //                 <span style={{fontWeight:600}}>DrinkGOOD</span>
                //             </div>
                //         </div>
                //     </>
                // :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* <Countdown second={15} /> */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            <div className='col-sm-12'>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ width: 600, backgroundColor: null, minHeight: "100vh", display: "flex", flexDirection: "column", gap: 5 }}>
                                        {
                                            trxlist.map((i, k) => {
                                                // console.log(i?.lab_request)
                                                return (
                                                    <div style={{ borderRadius: 5, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey" }}>
                                                        <div style={{ padding: 10 }}>
                                                            <div style={{ paddingTop: 5, paddingBottom: 5, fontWeight: 600, fontSize: 20 }}>{i.fullname}</div>
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', gap: 10 }}>
                                                                {
                                                                    // (i.prescription).length
                                                                    i.prescription.length > 0 ?
                                                                        <PrescriptionPDFViewer item={i} />
                                                                        // <>
                                                                        // {
                                                                        //     (i.prescription).map(()=>{
                                                                        //         return (
                                                                        //             <div style={{padding:10, width:200, backgroundColor:"#fff", boxShadow: "-1px 2px 5px grey", borderRadius:5}}>
                                                                        //                 Prescription
                                                                        //             </div>
                                                                        //         )
                                                                        //     })
                                                                        // }
                                                                        // </>
                                                                        : null
                                                                }
                                                                {
                                                                    // i.med_cert.patient_id
                                                                    i.med_cert.length > 0 ?
                                                                        <MedicationCertPDFViewer item={i} />
                                                                        : null
                                                                }
                                                                {
                                                                    (i?.lab_request?.serum == true ||
                                                                        i?.lab_request?.xray == true ||
                                                                        i?.lab_request?.ultrasound == true ||
                                                                        i?.lab_request?.ctscan == true ||
                                                                        i?.lab_request?.MRI == true ||
                                                                        i?.lab_request?.twelve_leads_ecg == true ||
                                                                        i?.lab_request?.twoD_echo == true ||
                                                                        i?.lab_request?.cardiac_stress_test == true ||
                                                                        i?.lab_request?.carotid_doppler_scan == true ||
                                                                        i?.lab_request?.arterial_duplex_scan == true ||
                                                                        i?.lab_request?.venous_duplex_scan == true) ?
                                                                        <LabRequestFormView item={i} />
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", padding: 10, backgroundColor: "#ddd", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                            <span>Transaction No: {i.transaction_id}</span>
                                                            <span>{i.date_time}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>

    );
}

export default Transactions;
