import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';
import { Dialog } from 'primereact/dialog';

const DeleteBtn = ({ onclickedpatient, onClickedPatient, onClickedButton, loadpatient }) => {
    const [open, setOpen] = useState(false);

    const onDelete = async () => {
        // sample('asd555555asd')
        var formData = new FormData();
        formData.append("delete_patient", 1);
        formData.append("patient_id", onclickedpatient.patient_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfully Deleted",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                loadpatient()
                setOpen(false)
                // window.location.assign('/')
                onClickedPatient(null)
                window.scrollTo(0, 0)
            });
        });
    }
    return (
        <>
            {/* You sure you want to remove this patient? */}
            <Dialog draggable={false} headerStyle={{ fontSize: 12, textAlign: "center", backgroundColor: "#f7a6a6", padding: 10 }} closable={false} blockScroll={true} header={<span style={{ color: "#fff", fontSize: 25 }}>Are you sure?</span>} visible={open} style={{ width: '30vw' }} onHide={() => setOpen(false)}>
                <div style={{ paddingBottom: 20, textAlign: "center", fontWeight: 600, fontSize: 20, padding:10 }}>
                    <span>
                        Name: <b>{onclickedpatient.fullname}</b>
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <button onClick={() => onDelete()} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "green", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Yes</button>
                    <button onClick={() => setOpen(false)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>No</button>
                </div>
            </Dialog>
            <button onClick={() => setOpen(true)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Delete</button>
        </>
    );
}

export default DeleteBtn;
