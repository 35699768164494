import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import ENV from '../ENV';
import styled from '@emotion/styled';

const PrescriptionPDFViewer = ({ item, width = 150, view = false }) => {
    // console.log(view)
    const [open, setOpen] = useState(false);
    return (
        <>
            <Sidebar blockScroll={true} visible={open} position="right" style={{ minHeight: 400, minWidth: "70%", borderRadius: 0 }} onHide={() => setOpen(false)}>
                <iframe src={ENV.DOMAIN + "presciptionLandScapePDF.php?trans_id=" + item.transaction_id} style={{ width: "100%", height: "100%" }} />
            </Sidebar>


            <div onClick={() => view === true ? null : setOpen(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: view === true ? null : "pointer", padding: 10, width: width, backgroundColor: "#fff", boxShadow: "-1px 2px 5px grey", borderRadius: 5 }}>
                <span>Prescription</span>
                {
                    view === true ?
                    <span onClick={() => setOpen(true)} style={{color: "green", cursor: "pointer"}}>View</span>
                    :null
                }
            </div>
        </>
    );
}

export default PrescriptionPDFViewer;
