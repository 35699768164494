import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './tubillaraclinic/Login'
import Dashboard from "./tubillaraclinic/Dashboard";
import axios from "axios";
import ENV from "./tubillaraclinic/ENV";
import { useEffect, useMemo, useState } from "react";
import Patient from "./tubillaraclinic/Patient";
import Transactions from "./tubillaraclinic/Transactions";
import Calendar from "./tubillaraclinic/Calendar";
import { AppProvider } from "./tubillaraclinic/context/AppContext";
import Reports from "./tubillaraclinic/Reports";
// import firebase from "./firebase";
import io from "socket.io-client";

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { format } from "date-fns";
import PosLogin from "./pos/Login";
import PosDashboard from "./pos/Dashboard";
import PosProduct from "./pos/Product";
import PosCashier from "./pos/Cashier";

import dir from 'C:\\Windows\\System32\\drivers\\etc\\react_register.txt';
import { ProgressSpinner } from "primereact/progressspinner";

function App() {
    // localStorage.removeItem("computerKey")

    disableReactDevTools();      
    // const socket = io.connect("http://192.168.1.11:3001");

    const position = sessionStorage.getItem('position');

    const [computerAddress, setComputerAddress] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [timeout, settimeout] = useState(0);
    const [newtransaction, setNewTransaction] = useState([])




    // const joinRoom = () => {
    //     socket.emit("join_room", '546546546')
    // }
    // useEffect(async()=>{     

    //     var formData = new FormData();
    //     formData.append("profile", 1);
    //     formData.append("user_id", sessionStorage.getItem('user_id'));

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         // console.log(res.data)
    //         if(res.data[0]) {
    //             setUserInfo(res.data[0])
    //             setisLoading(false)
    //         } 
    //         // else {                
    //         //     setisLoading(false)
    //         //     sessionStorage.removeItem('user_id')
    //         //     sessionStorage.removeItem('username')
    //         // }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // },[setUserInfo]);

 

    useEffect(()=>{    
        // socket.emit("clinic_data", {room: 123})
        // socket.on("receive_data", (data) => {
        //     if(data != null){                    
        //         console.log(data)
        //         setNewTransaction(data)
        //     }
        // })

        fetch(dir)
        .then(row => row.text())
        .then(text => {
            // setComputerAddress(text)
            if (text !== localStorage.getItem("computerKey")) {
                // console.log('exit'+text)
                localStorage.setItem("computerKey", text);
                return;
            }
            // console.log('renew')
            localStorage.setItem("computerKey", text);
        });


    }, [dir])

    // console.log(localStorage.getItem("computerKey"))
    
    // if (localStorage.getItem("computerKey") !== 'tubillara') {
    //     return (
    //         <div style={{
    //             width:"100vw", 
    //             height:"100vh", 
    //             display:"flex", 
    //             flexDirection: "row", 
    //             justifyContent:"center", 
    //             alignItems:"center",
    //             fontSize: 60,
    //             fontWeight: 600,
    //             color: "rgb(255 124 124)",
    //             textShadow: "0 0 3px #000, 0 0 5px #000"
    //         }}>
    //             Device not allowed!
    //         </div>
    //     )
    // }

    setTimeout(() => {
        setisLoading(true)
    }, 1000);

    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">

                        {

                            sessionStorage.getItem('username') == null ?
                                isLoading ?
                                    (
                                        <Login />
                                    )
                                    :
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                        <span>Please wait...</span>
                                    </div>
                                :
                                <Patient userInfo={userInfo} newtransaction={newtransaction} />
                        }
                    </Route>
                    <Route exact={true} path="/dashboard">
                        {sessionStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/patient">
                        {

                            position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' ?
                                sessionStorage.getItem('username') == null ? 
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Patient userInfo={userInfo} newtransaction={newtransaction} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/calendar">
                        {sessionStorage.getItem('username') == null ? <Login /> : <Calendar userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/vitals">
                        {sessionStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/medication">
                        {sessionStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/consultation">
                        {sessionStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    <Route exact={true} path="/billing">
                        {
                            position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' || position == 'cashier' ?
                                sessionStorage.getItem('username') == null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Dashboard userInfo={userInfo} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/transaction">
                        {
                            position == 'master_admin' || position == 'admin_doctor' || position == 'admin_secretary' ?
                                sessionStorage.getItem('username') == null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Transactions userInfo={userInfo} />
                                : null
                        }
                    </Route>
                    <Route exact={true} path="/report">
                        {
                            position == 'master_admin' || position == 'admin_doctor' ?
                                sessionStorage.getItem('username') == null ?
                                    isLoading ?
                                        (
                                            <Login />
                                        )
                                        :
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            <span>Please wait...</span>
                                        </div>
                                    :
                                    <Reports userInfo={userInfo} />
                                : null
                        }
                    </Route>

                    <Route exact={true} path="/pos">
                        <PosLogin />
                    </Route>

                    <Route exact={true} path="/pos/dashboard">
                        <PosDashboard />
                    </Route>

                    <Route exact={true} path="/pos/product">
                        <PosProduct />
                    </Route>

                    <Route exact={true} path="/pos/cashier">
                        <PosCashier/>
                    </Route>
                </Switch>
            </Router>
        </AppProvider>
    )
}

export default App;
