import React, { useEffect, useMemo, useRef, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment/moment';
import useLongPress from '../extra/useLongPress'; 
import validator from 'validator'
import { Image } from 'primereact/image';
import ChartingImageIcon from '../extra/ChartingImageIcon';


const ChartingTable = ({ scrollEndRef, chartingList, loadData, onclickedpatient, onConsultationButton, medCertData, index, prescriptiondata }) => {

    const inputRef = useRef();


    // const scrollEndRef = useRef(null)

    // const scrollToBottom = () => {
    //     scrollEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // }

    const [inputValue, setInputValue] = useState({
        "id": "",
        "patient_id": onclickedpatient.patient_id,
        "focus": "",
        "progress": "",
        "time": "",
        "date_time": ""
    });
    // const [chartingList, setChartingList] = useState([]);
    const [selected, setSelected] = useState();

    const [addRow, setAddRow] = useState([])



    // useMemo(async () => {
    //     var formData = new FormData();
    //     formData.append("charting_list", 1); //onclickedpatient.patient_id
    //     formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'process.php',
    //         data: formData,
    //     }).then((res) => {
    //         // console.log(res.data);
    //         setChartingList(res.data)
    //     })
    // }, [ENV, onclickedpatient])

    // const loadData = async () => {
    //     var formData = new FormData();
    //     formData.append("charting_list", 1); //onclickedpatient.patient_id
    //     formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'process.php',
    //         data: formData,
    //     }).then((res) => {
    //         // console.log(res.data);
    //         setChartingList(res.data)
    //     })
    // }


    const onPress = async (e) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        }
        else if (e.which == 13) {

            if (selected) {
                var formData = new FormData();
                formData.append("update_charting", 1); //onclickedpatient.patient_id
                formData.append("id", selected.id); //onclickedpatient.patient_id
                formData.append("patient_id", onclickedpatient.patient_id);
                formData.append("focus", inputValue.focus); //onclickedpatient.patient_id
                formData.append("progress", inputValue.progress); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
                setSelected()
            } else {
                var formData = new FormData();
                formData.append("add_charting", 1); //onclickedpatient.patient_id
                formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
                formData.append("focus", inputValue.focus); //onclickedpatient.patient_id
                formData.append("progress", inputValue.progress); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
            }



            // setChartingList([...chartingList, { focus: inputValue.focus, progress: inputValue.progress }])
        }
    }

    const onPressUpdate = async (e, i) => {
        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        } else
            if (e.which == 13) {
                console.log(e.target.name, e.target.value, i)
                // return;
                var formData = new FormData();
                formData.append("update_charting_row", 1); //onclickedpatient.patient_id
                formData.append("patient_id", onclickedpatient.patient_id);
                formData.append("id", i); //onclickedpatient.patient_id
                formData.append("field", e.target.name); //onclickedpatient.patient_id
                formData.append("value", e.target.value); //onclickedpatient.patient_id
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // console.log(res.data);
                    // setTrxList(res.data)
                })
                // console.log(e)
                setInputValue({ focus: '', progress: '' })
                loadData()
                setSelected()
            }
    }

    const remove = async (item) => {
        var formData = new FormData();
        formData.append("remove_charting", 1); //onclickedpatient.patient_id
        formData.append("patient_id", onclickedpatient.patient_id); //onclickedpatient.patient_id
        formData.append("id", item?.id); //onclickedpatient.patient_id
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(e)
            setInputValue({ focus: '', progress: '' })
            loadData()
            setSelected()
        })
    }

    const onPressInputField = async (e) => {



        if (e.which == 13 && e.shiftKey) {
            // console.log(e)
        }
        else if (e.which == 13) {
            setAddRow([...addRow, { patient_id: onclickedpatient.patient_id, focus: inputValue.focus, progress: inputValue.progress }])
            setInputValue({ focus: '', progress: '' })
            inputRef.current.focus();
        }
    }

    const removerow = async (item) => {

        const data = addRow.filter((i) => i != item);
        // console.log(addRow)
        setAddRow(data)
    }

    // console.log(chartingList)



    const groupedData = chartingList?.reduce((groups, item) => {
        const { date, date_time, time } = item;
        // return date_time
        // console.log(product_id)
        // if (product_id == 0) {
        //     if (!groups[product_id, product_name]) {
        //         groups[product_id, product_name] = [];
        //     }
        //     groups[product_id, product_name].push(item);
        // } else {
        if (!groups[moment(date).format('hh:mm:ss')]) {
            groups[moment(date).format('hh:mm:ss')] = [];
        }
        groups[moment(date).format('hh:mm:ss')].push(item);
        // }
        return groups;
    }, {});


    const [doubleclickin, setDoubleClickinRow] = useState(null)
    const [edittext, setEditText] = useState('')
    const [singleClick, setSingleClick] = useState('')



    const onLongPress = (e) => {
        // console.log('longpress is triggered ' + e);
    };

    const onClick = (e) => {
        // console.log('click is triggered ' + e)
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    const handleClick = (e, items) => {
        switch (e.detail) {
            case 1:
                // console.log("click");
                setDoubleClickinRow({})
                setSingleClick({})
                break;
            case 2:
                // console.log("double click" + JSON.stringify(items));
                setDoubleClickinRow(items)
                setEditText(items)
                setSingleClick(items)
                // e.target.select()
                break;
            case 3:
                // console.log("triple click");
                break;
        }
    };

    
    const trimSpace = (str) => {
        return str.replace(/^[ \t]+|[ \t]+$/g, '')
    }
    // console.log(singleClick.id)

    useEffect(() => {
        // setTimeout(()=> {
        //     scrollEndRef.current?.scrollIntoView({ behavior: 'smooth' })
        // }, 1000)
    }, [scrollEndRef]);

    const icon = (<i className="pi pi-search"></i>)

    return (
        <div>
            {/* {
                chartingList.map((item, key) => {
                    console.log(item.date, 1)
                    Object.entries(item.list)?.map(([date, items]) => {
                        console.log(moment(items.date_time).format('hh:mm:ss A')+' => '+items.focus+' => '+items.progress, 2)
                        return (
                            <div>{items.focus}</div>
                        )
                    })
                })
            } */}
            {/* <button {...longPressEvent}>use  Loooong  Press</button> */}

            {/* <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th style={{width: 50, fontSize:12, padding: 5}}>Date / Time</th>
                        <th style={{width: 100, fontSize:12, padding: 5}}>Focus</th>
                        <th style={{width: 100, fontSize:12, padding: 5}}>Progress</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        chartingList.map((item, key) => {
                            return (
                                <tr>
                                    <td style={{width: 50, fontSize:12, padding: 5}}>asd</td>
                                    <td style={{width: 100, fontSize:12, padding: 0, border:"none"}}>
                                        <table style={{width:"100%", border:"none"}}>
                                            <tbody>                                                
                                                {
                                                    item.list.map((items, keys) => {
                                                        return (
                                                            <tr>
                                                                <th style={{width: "100%", minHeight:40, fontSize:12, padding: 5}}>{items.focus != '' ? items.focus : <div style={{height: 18}}></div>}</th>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{width: 100, fontSize:12, padding: 0, border:"none"}}>
                                        <table style={{width:"100%", border:"none"}}>
                                            <tbody>                                                
                                                {
                                                    item.list.map((items, keys) => {
                                                        return (
                                                            <tr>
                                                                <th style={{width: "100%", minHeight:40, fontSize:12, padding: 5}}>{items.progress != '' ? items.progress : <div style={{height: 18}}></div>}</th>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table> */}
            {
                // window.innerWidth
            }
            
            <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: "1px solid #ddd" }}>
                <div style={{ width: 220, textAlign: "center" }}>
                    <span style={{ padding: 10, fontWeight: 600 }}>Date / Time</span>
                </div>
                <div style={{ width: 200 }}>
                    <span style={{ padding: 10, fontWeight: 600 }}>Focus</span>
                </div>
                <div style={{ width: 200 }}>
                    <span style={{ padding: 10, fontWeight: 600 }}>Progress</span>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 0, maxHeight: 500, overflowX: "hidden" }} className='hidescroll'>
                <div>
                    {
                        [...chartingList].reverse().map((item, key) => {
                            return (
                                <div key={key} ref={scrollEndRef} style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "100%", border: "1px solid #ddd", borderTop: key != 0 ? null : "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: 10, backgroundColor: "#fff" }}>
                                        <div style={{ width: 100, padding: 5, fontSize: 15 }}>{item.date}</div>
                                        <div style={{ width: window.innerWidth == 1080 ? "85%" : "88%", borderLeft: "1px solid #ddd" }}>
                                            {
                                                item.list.map((items, keys) => {
                                                    return (
                                                        <div key={keys} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 5, alignItems: "center" }}>
                                                            <div style={{ position: "relative", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 0 }}>
                                                                <div style={{ width: 100, fontSize: 15, padding: 5, borderTop: keys >= 0 ? null : "1px solid #ddd" }}>
                                                                    {keys == 0 ? moment(items.date_time).format('hh:mm:ss A') : null}
                                                                </div>
                                                                <div onClick={(e) => doubleclickin?.id == items.id && doubleclickin?.focus == items.focus ? null : items.images != '' ? null : handleClick(e, items) } style={{ width: 200, padding: doubleclickin?.id == items.id && doubleclickin?.focus == items.focus ? 0 : 5, fontSize: 15, borderLeft: "1px solid #ddd", borderTop: keys == 0 ? null : "1px solid #ddd", wordWrap: "break-word" }}>
                                                                    {
                                                                        doubleclickin?.id == items.id && doubleclickin?.focus == items.focus ?
                                                                            <>
                                                                                {
                                                                                    items.images != '' ?
                                                                                        <div style={{padding: 5}}>
                                                                                            <ChartingImageIcon onclickedpatient={onclickedpatient} items={items} />
                                                                                            {/* <Image downloadable={true} downloadIcon={false} src={ENV.DOMAIN + 'charting/' + items.images} indicatorIcon={icon} alt="Image" preview width="100" /> */}
                                                                                            {/* <img src={ENV.DOMAIN + 'charting/'+items.images} style={{width: 100, height: 100}} /> */}
                                                                                        </div>
                                                                                        :
                                                                                        <textarea style={{ width: "100%", border: 0, outline: "none", overflow: "hidden", padding: 5 }}
                                                                                            name='focus'
                                                                                            onChange={(e) => setEditText({ id: items.id, [e.target.name]: e.target.value, progress: items.progress })}
                                                                                            value={edittext ? edittext.focus : items.focus}
                                                                                            onKeyPress={(e) => onPressUpdate(e, items.id)}
                                                                                        />
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    items.images != '' ?
                                                                                        <span>
                                                                                            <ChartingImageIcon onclickedpatient={onclickedpatient} items={items} />
                                                                                            {/* <Image downloadable={true} downloadIcon={false} src={ENV.DOMAIN + 'charting/' + items.images} indicatorIcon={icon} alt="Image" preview width="100" /> */}
                                                                                            {/* <img src={ENV.DOMAIN + 'charting/'+items.images} style={{width: 100, height: 100}} /> */}
                                                                                        </span>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                items.focus.split("\n").map((item) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                        { item }
                                                                                                        < br />
                                                                                                        </>
                                                                                                )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div onClick={(e) => doubleclickin?.id == items.id && doubleclickin?.progress == items.progress ? null : handleClick(e, items)} style={{ width: window.innerWidth == 1080 ? 200 : window.innerWidth == 1366 ? 550 : window.innerWidth == 1920 ? 620 : 350, padding: doubleclickin?.id == items.id && doubleclickin?.progress == items.progress ? 0 : 5, fontSize: 15, borderLeft: "1px solid #ddd", borderTop: keys == 0 ? null : "1px solid #ddd", wordWrap: "break-word" }}>
                                                                    {
                                                                        doubleclickin?.id == items.id && doubleclickin?.progress == items.progress ?
                                                                            <textarea style={{ width: "90%", border: "none", padding: 5, outline: "none" }}
                                                                                name='progress'
                                                                                onChange={(e) => setEditText({ id: items.id, focus: items.focus, [e.target.name]: e.target.value, })}
                                                                                value={edittext ? edittext.progress : items.progress}
                                                                                onKeyPress={(e) => onPressUpdate(e, items.id)}
                                                                            />
                                                                            :
                                                                            <>
                                                                                {
                                                                                    items.progress.split("\n").map((item) => {
                                                                                        return (
                                                                                            <>
                                                                                            { item }
                                                                                            < br />
                                                                                            </>
                                                                                    )
                                                                                    })
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                                {
                                                                    singleClick.id == items.id ?
                                                                        <div style={{ width: 35, height: 35, display: "flex", padding: 5, borderRadius: 5, backgroundColor:"rgb(182 57 57)", border: "1px solid red", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer", position:"absolute", top: 10, right: 20 }}>
                                                                            <span onClick={() => remove(items)} className='pi pi-trash' style={{ color: "#fff" }}></span>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    );
}

export default ChartingTable;
