import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';

const BillingPaymentBtn = ({ valueinput, changevalue, proceedButton, onclickedpatient, trxdetails }) => {


    return (
        <div>
            {
                trxdetails.status == 'paid' ?
                    <></>
                    :
                    <>
                        {
                            trxdetails.method == 'Free' ?
                                <>
                                    {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <button onClick={()=>proceedButton()} style={{display:"flex", width: 200, justifyContent: "center", alignItems: "center", fontWeight: 600, borderRadius: 5, backgroundColor: "rgb(67, 190, 160)", color: "#fff", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey", height: 40}}>Complete</button>
                        </div> */}
                                </>
                                : null
                        }
                        {
                            trxdetails.method == 'Insurance' ?
                                <>
                                    {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                            <button onClick={()=>proceedButton()} style={{display:"flex", width: 200, justifyContent: "center", alignItems: "center", fontWeight: 600, borderRadius: 5, backgroundColor: "rgb(67, 190, 160)", color: "#fff", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey", height: 40}}>Complete</button>
                        </div> */}
                                </>
                                : null
                        }
                        {
                            trxdetails.method == 'Input Amount' ?
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                    {
                                        Number(trxdetails.amount) == Number(valueinput.tendered).toFixed(2) ?
                                            <button onClick={() => proceedButton()} style={{ display: "flex", width: 200, justifyContent: "center", alignItems: "center", fontWeight: 600, borderRadius: 5, backgroundColor: "rgb(67, 190, 160)", color: "#fff", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey", height: 40 }}>Complete Payment</button>
                                            : null
                                    }
                                </div>
                                : null
                        }
                    </>
            }
        </div>
    );
}

export default BillingPaymentBtn;
