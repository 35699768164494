import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';

const ConsultaionOption = ({ onclickedpatient, onConsultationButton }) => {

    if (localStorage.getItem('PrescriptionList')) {
        var prescriptlist = localStorage.getItem('PrescriptionList');
    } else {
        var prescriptlist = JSON.stringify([]);
    }

    if (localStorage.getItem('medcert')) {
        var medcert = localStorage.getItem('medcert');
    } else {
        var medcert = JSON.stringify([]);
    }

    const { state, dispatch } = useContext(AppContext);

    const [prescriptionlist, setPrescriptionList] = useState(JSON.parse(prescriptlist))
    const prescriptiondata = prescriptionlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    const [medCertData, setMedCertData] = useState(JSON.parse(medcert))
    const medcertdata = medCertData.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    
    if(localStorage.getItem('LabRequestCheckBox')) {
        var LabRequestCheckBox = JSON.parse(localStorage.getItem('LabRequestCheckBox'));
        // console.log(1)
    } else {
        var LabRequestCheckBox = {};
        // console.log(2)
    }

    return (
        <div style={{ padding: 5, backgroundColor: "#fff", minHeight: "100vh" }}>
            <div style={{ padding: 5, display: "flex", flexDirection: "column", gap: 10 }}>
                <span style={{ fontSize: 30, fontWeight: 600 }}>Consultation</span>
                <span style={{ fontSize: 25, fontWeight: 600 }}>What would you like to do?</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <div onClick={() => onConsultationButton('New Prescription')} style={{ padding: 5, width: "100%", height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 5, boxShadow: "-1px 2px 5px grey", cursor: "pointer" }}>
                    <span style={{ fontSize: 15, fontWeight: 600 }}>Create Prescription</span>
                    {
                        prescriptiondata.length > 0 ?
                            <i className="pi pi-check-circle" style={{ fontSize: '1.2rem' }}></i>
                            : null
                    }
                </div>
                <div onClick={() => onConsultationButton('New Medical Certificate')} style={{ padding: 5, width: "100%", height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 5, boxShadow: "-1px 2px 5px grey", cursor: "pointer" }}>
                    <span style={{ fontSize: 15, fontWeight: 600 }}>Medical Certificate</span>
                    {
                        medcertdata.length > 0 ?
                            <i className="pi pi-check-circle" style={{ fontSize: '1.2rem' }}></i>
                            : null
                    }
                </div>
                <div onClick={() => onConsultationButton('Lab Request Form')} style={{ padding: 5, width: "100%", height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 5, boxShadow: "-1px 2px 5px grey", cursor: "pointer" }}>
                    <span style={{ fontSize: 15, fontWeight: 600 }}>Lab Request Form</span>
                    {
                        (LabRequestCheckBox?.cbc == true || 
                            LabRequestCheckBox?.platelet == true || 
                            LabRequestCheckBox?.blood_type == true || 
                            LabRequestCheckBox?.serum == true ||
                            LabRequestCheckBox?.urinalysis == true ||
                            LabRequestCheckBox?.stool_exam == true ||
                            LabRequestCheckBox?.xray == true ||
                            LabRequestCheckBox?.ultrasound == true ||
                            LabRequestCheckBox?.ctscan == true ||
                            LabRequestCheckBox?.MRI == true ||
                            LabRequestCheckBox?.twelve_leads_ecg == true ||
                            LabRequestCheckBox?.twoD_echo == true ||
                            LabRequestCheckBox?.cardiac_stress_test == true ||
                            LabRequestCheckBox?.carotid_doppler_scan == true ||
                            LabRequestCheckBox?.arterial_duplex_scan == true ||
                            LabRequestCheckBox?.venous_duplex_scan == true) ?
                            <i className="pi pi-check-circle" style={{ fontSize: '1.2rem' }}></i>
                            : null
                    }
                </div>
                <div>
                    {
                        prescriptiondata.length > 0 || medcertdata.length > 0 || (LabRequestCheckBox?.cbc == true || 
                            LabRequestCheckBox?.platelet == true || 
                            LabRequestCheckBox?.blood_type == true || 
                            LabRequestCheckBox?.serum == true ||
                            LabRequestCheckBox?.urinalysis == true ||
                            LabRequestCheckBox?.stool_exam == true ||
                            LabRequestCheckBox?.xray == true ||
                            LabRequestCheckBox?.ultrasound == true ||
                            LabRequestCheckBox?.ctscan == true ||
                            LabRequestCheckBox?.MRI == true ||
                            LabRequestCheckBox?.twelve_leads_ecg == true ||
                            LabRequestCheckBox?.twoD_echo == true ||
                            LabRequestCheckBox?.cardiac_stress_test == true ||
                            LabRequestCheckBox?.carotid_doppler_scan == true ||
                            LabRequestCheckBox?.arterial_duplex_scan == true ||
                            LabRequestCheckBox?.venous_duplex_scan == true) ?
                            <button onClick={() => onConsultationButton('patient_billing')} style={{ padding: 10, height: 50, borderRadius: 5, backgroundColor: "#43bea0", color: "#fff", border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>
                                <span style={{ fontSize: 18, fontWeight: 600 }}>Proceed to Patient Billing</span>
                            </button>
                            : null
                    }
                </div>
            </div>
        </div>
    );
}

export default ConsultaionOption;
