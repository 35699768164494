import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';

const VitalSingleHistory = ({ onclickedpatient, onClickedButton, setMedicalRecordButton }) => {

    const position = sessionStorage.getItem('position');

    const [vitaldetails, setVitalDetails] = useState(null)
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_current_vitals", 1);
        formData.append("patient_id", onclickedpatient.patient_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data[0])
            setVitalDetails(res.data[0])
        })
    }, [onclickedpatient, ENV])

    return (
        <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 18, fontWeight: 600 }}>Vital</div>
                    <div style={{ fontSize: 18, fontWeight: 500 }}>
                        {
                            position != 'cashier' ?
                            <button onClick={() => setMedicalRecordButton('Vital_history_list')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                                <span>View History</span>
                                <span className='pi pi-arrow-right' />
                            </button>
                            :null
                        }
                    </div>
                </div>
                <div>
                    <div><i className='pi pi-calendar' /> {vitaldetails?.date_time}</div>
                </div>
            </div>
            {
                vitaldetails ?
                <div style={{ padding: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Weight</div>
                            <div>{vitaldetails?.weight}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Height</div>
                            <div>{vitaldetails?.height}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Blood Type</div>
                            <div>{vitaldetails?.blood_type}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Temperature</div>
                            <div>{vitaldetails?.tepm}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Systolic</div>
                            <div>{vitaldetails?.systolic}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Diastolic</div>
                            <div>{vitaldetails?.diastolic}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Heart Rate</div>
                            <div>{vitaldetails?.heart_rate}</div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>Respiration Rate</div>
                            <div>{vitaldetails?.respiration_rate}</div>
                        </div>
                    </div>
                </div>
                : null
            }
            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <button onClick={() => onClickedButton('AddVitalForm')} style={{ fontSize: 18, height: 40, borderRadius: 5, backgroundColor: "#62afd7", border: "3px solid #fff", color: "#fff", fontWeight: 600, width: 150, boxShadow: "-1px 2px 5px grey" }}>Take Vitals</button>
                </div>
            </div>
        </div>
    );
}

export default VitalSingleHistory;
