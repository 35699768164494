import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';

const PrescriptionsPreview = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
        <Sidebar visible={open} header={<>Change Password</>} position="right" style={{ minHeight: 400, minWidth:500, borderRadius: 0 }} onHide={() => setOpen(false)}>
            {/* <iframe src={"http://localhost/tubillara_clinic/medical_cetificatePDF.php?trans_id="+item.transaction_id} style={{width:"100%", height: "100%"}} /> */}
        </Sidebar>
        
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: 500, height: 50, padding: 10, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
            <span style={{ fontWeight: 600, fontSize: 15 }}>Prescription</span>
            {/* <span onClick={()=>setOpen(true)} style={{cursor:"pointer", textDecoration:"underline", color:"#43bea0"}}>view</span> */}
        </div>
        </>
    );
}

export default PrescriptionsPreview;
