import React, { useMemo, useState } from 'react';
import BillingPaymentBtn from './Billing_payment_btn';
import axios from 'axios';
import ENV from '../ENV';
import PrescriptionPDFViewer from '../extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from '../extra/MedicationCertPDFViewer';
import LabRequestFormView from '../extra/LabRequestFormView';
import { InputNumber } from 'primereact/inputnumber';
import swal from 'sweetalert';

const BillingPayment = ({ onclickedpatient, trxdetails, setTrxDetails, reloadData }) => {
    // console.log(trxdetails)

    const [valueinput, setValueInput] = useState({tendered: 0})
    const [changevalue, setChangeValue] = useState(0)

    const format = (n, sep, decimals) => {
        // setSubTotal(n);
        sep = sep || "."; // Default to period as decimal separator
        decimals = decimals || 2; // Default to 2 decimals

        return n.toLocaleString().split(sep)[0]
            + sep
            + n.toFixed(decimals).split(sep)[1];
    }


    const proceedButton = async () => {
        // console.log(trxdetails)
        if (Number(trxdetails.amount) <= Number(valueinput.tendered).toFixed(2)) {
            console.log(Number(trxdetails.amount), Number(valueinput.tendered).toFixed(2))
        }

        var formData = new FormData();
        formData.append("complete_transaction", 1);
        formData.append("transaction_id", trxdetails.transaction_id);
        formData.append("patient_id", onclickedpatient.patient_id);
        formData.append("tendered", valueinput.tendered);
        formData.append("change", changevalue);
        formData.append("receiveby", sessionStorage.getItem('username'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfull Paid",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    reloadData()
                    setValueInput({tendered: 0})
                    setChangeValue(0)
                    trxdetails.status = 'paid';
                    setTrxDetails(trxdetails)
                });
            }
            // console.log(res.data);
            // setTrxDetails(0)
            reloadData()
            setValueInput({tendered: 0})
            setChangeValue(0)
        })

    }

    useMemo(()=> {
        if (Number(trxdetails.amount) <= Number(valueinput.tendered).toFixed(2)) {
            if (Number(trxdetails.amount) < Number(valueinput.tendered).toFixed(2)) {
                setChangeValue((Number(valueinput.tendered).toFixed(2) - Number(trxdetails.amount)).toFixed(2))
            } else if (Number(trxdetails.amount) > Number(valueinput.tendered).toFixed(2)) {
                setChangeValue((Number(trxdetails.amount) - Number(valueinput.tendered).toFixed(2)).toFixed(2))
            }
        } else if (Number(valueinput.tendered).toFixed(2)) {
            setChangeValue('0.00')
            // setChangeValue((Number(valueinput.tendered).toFixed(2) - Number(trxdetails.amount)).toFixed(2))
        }
    }, [valueinput, trxdetails])

    // useMemo(async () => {

    //     var formData = new FormData();
    //     formData.append("transaction_list", 1);
    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN + 'process.php',
    //         data: formData,
    //     }).then((res) => {
    //         // console.log(res.data);
    //     })
    // }, [ENV])





    // console.log(valueinput)



    return (
        <div className='col-sm-6'>
            {
                // JSON.stringify(trxdetails)
            }
            <div style={{ paddingRight: 10 }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 5 }}>
                    <div style={{ textAlign: "center" }}><span style={{ fontSize: 20, fontWeight: 600 }}>Summary</span></div>
                    {/* <div style={{ textAlign: "center" }}><span>Transaction ID: <b style={{ textDecoration: "underline" }}>{" " + trxdetails.transaction_id + " "}</b></span></div> */}
                </div>
                <div>
                    <div>
                        {/* <span>Document/s: {trxdetails.amount}</span> */}
                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                            {
                                trxdetails?.prescription?.length > 0 ?
                                    <PrescriptionPDFViewer item={trxdetails} width={"100%"} view={true} />
                                    : null
                            }
                            {
                                trxdetails?.med_cert?.length > 0 ?
                                    <MedicationCertPDFViewer item={trxdetails} width={"100%"} view={true} />
                                    : null
                            }
                            {
                                (trxdetails?.lab_request?.serum == true ||
                                    trxdetails?.lab_request?.xray == true ||
                                    trxdetails?.lab_request?.ultrasound == true ||
                                    trxdetails?.lab_request?.ctscan == true ||
                                    trxdetails?.lab_request?.MRI == true ||
                                    trxdetails?.lab_request?.twelve_leads_ecg == true ||
                                    trxdetails?.lab_request?.twoD_echo == true ||
                                    trxdetails?.lab_request?.cardiac_stress_test == true ||
                                    trxdetails?.lab_request?.carotid_doppler_scan == true ||
                                    trxdetails?.lab_request?.arterial_duplex_scan == true ||
                                    trxdetails?.lab_request?.venous_duplex_scan == true) ?
                                    <LabRequestFormView item={trxdetails} width={"100%"} view={true} />
                                    : null
                            }
                        </div>
                    </div>
                    {
                        trxdetails.method == 'Free' ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                <span style={{ fontSize: 25, fontWeight: 600 }}>{"Free"}</span>
                            </div>
                            : null
                    }
                    {
                        trxdetails.method == 'Insurance' ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                <span style={{ fontSize: 25, fontWeight: 600 }}>{"Insurance"}</span>
                            </div>
                            : null
                    }
                    {/* {
                        trxdetails.method == 'Input Amount' ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                <span>Amount:</span>
                                <span style={{ fontSize: 25, fontWeight: 600 }}>{(trxdetails.amount)}</span>
                            </div>
                            : null
                    } */}
                    {
                        trxdetails.method == 'Input Amount' ?
                        <>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                <span>Amount:</span>
                                <span style={{ fontSize: 25, fontWeight: 600 }}>{(trxdetails.amount)}</span>
                            </div>
                            {
                                trxdetails.status == 'paid' ?
                                <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                    <span style={{ fontSize: 25, fontWeight: 600, color: "green" }}>PAID</span>
                                </div>
                                </>
                                :
                                <>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                        <span style={{ fontWeight: 600 }}>Tendered:</span>
                                        <div>
                                            {/* <InputNumber inputId="locale-user" value={valueinput?.tendered} onValueChange={(e) => setValueInput(e.value)} minFractionDigits={2} /> */}
                                            <input type={"number"} name='tendered' onChange={(e) => setValueInput({ ...valueinput, [e.target.name]: e.target.value })} placeholder='0.00' style={{ height: 35, width: 150, borderRadius: 5, border: "1px solid #ddd", fontSize: 20, textAlign: "right", padding: 5 }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10, padding: 5 }}>
                                        <span style={{ fontWeight: 600 }}>Change:</span>
                                        <div>
                                            <span style={{ fontSize: 25, fontWeight: 600 }}>{(changevalue)}</span>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                            : null
                    }
                </div>
                <BillingPaymentBtn valueinput={valueinput} changevalue={changevalue} proceedButton={proceedButton} onclickedpatient={onclickedpatient} trxdetails={trxdetails} />
            </div>
        </div>
    );
}

export default BillingPayment;
