import React from 'react';

const Card = ({item, selecteUpdate, children}) => {
    return (
        <div>
            <div onClick={() => selecteUpdate(item)} style={{ width: 150, height: 150, backgroundColor: "rgb(106 185 176)", borderRadius: 5, padding: 5, border:"1px solid #ddd", color: "#fff" }}>
                <span>{children}</span>
            </div>
        </div>
    );
}

export default Card;
