import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import VitalBox from '../extra/VitalBox';

const VitalHistory = ({ onclickedpatient }) => {
    const [vitallist, setVitalList] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_vital_list", 1);
        formData.append("patient_id", onclickedpatient.patient_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setVitalList(res.data)
        })
    }, [onclickedpatient, ENV])
    
    if (vitallist.length>0) {
        return (
            <div style={{display:"flex", flexDirection:"column", gap:10}}>
                {
                    vitallist.map((item, key) => {                        
                        return (
                            <VitalBox key={key} item={item} />
                        )
                    })
                }
            </div>
        );    
    }
    return (<></>)
}

export default VitalHistory;
