import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';
import DeleteBtn from '../extra/DeleteBtn';

const EditPatientForm = ({ onclickedpatient, onClickedPatient, onClickedButton, loadpatient }) => {

    const position = sessionStorage.getItem('position');

    const [genderSelect, setGenderSelect] = useState(onclickedpatient?.gender)

    const [inputform, setInputForm] = useState({
        "patient_id": onclickedpatient?.patient_id,
        "firstname": onclickedpatient?.firstname,
        "middlename": onclickedpatient?.middlename,
        "lastname": onclickedpatient?.lastname,
        "suffix": onclickedpatient?.suffix,
        "gender": onclickedpatient?.gender,
        "birthday": onclickedpatient?.birthday,
        "civil_status": onclickedpatient?.civil_status,
        "contact": onclickedpatient?.contact,
        "address": onclickedpatient?.address,
        "email_address": onclickedpatient?.email_address,
        "weight": "",
        "height": "",
        "blood_type": "",
        "temperature": "",
        "systolic": "",
        "diastolic": "",
        "heart_rate": "",
        "respiration_rate": "",
        "allergies": onclickedpatient?.allergies,
        "chronic_diseases": onclickedpatient?.chronic_diseases,
        "occupation": onclickedpatient?.occupation,
    })

    const onSubmit = async () => {
        // alert(onclickedpatient.patient_id)
        var formData = new FormData();
        formData.append("patient_update", 1);
        formData.append("patient_id", inputform.patient_id);
        formData.append("firstname", inputform.firstname);
        formData.append("middlename", inputform.middlename);
        formData.append("lastname", inputform.lastname);
        formData.append("suffix", inputform.suffix);
        formData.append("gender", inputform.gender);
        formData.append("birthday", inputform.birthday);
        formData.append("civil_status", inputform.civil_status);
        formData.append("contact", inputform.contact);
        formData.append("address", inputform.address);
        formData.append("email_address", inputform.email_address);
        formData.append("weight", inputform.weight);
        formData.append("height", inputform.height);
        formData.append("blood_type", inputform.blood_type);
        formData.append("temperature", inputform.temperature);
        formData.append("systolic", inputform.systolic);
        formData.append("diastolic", inputform.diastolic);
        formData.append("heart_rate", inputform.heart_rate);
        formData.append("respiration_rate", inputform.respiration_rate);
        formData.append("allergies", inputform.allergies);
        formData.append("chronic_diseases", inputform.chronic_diseases);
        formData.append("occupation", inputform.occupation);
        // formData.append("gender", inputform.gender);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            swal({
                title: "Done!",
                text: "Successfull Updated",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                // console.log(res.data);
                onClickedPatient(res.data);
                loadpatient();
                onClickedButton('PatientInfoPanel')
                window.scrollTo(0, 0)
            });
        })
    }

    // console.log(inputform)

    return (
        <>
            {/* {
            window.innerWidth == 1080 ?
                <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <button onClick={() => setEditPersonalInfo(false)} style={{ borderRadius: 5, border: "1px solid #ddd", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold" }}>Cancel</button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                        <div>
                            <div style={{ borderRadius: 100, width: 50, height: 50, backgroundColor: "#ddd" }}></div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ fontSize: 18, fontWeight: 600 }}>Full Name</div>
                            <div style={{ fontSize: 12, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <span>Gender</span>
                                <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                                <span>Age</span>
                            </div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div>00000000000</div>
                            <div>00000000000@gmail.com</div>
                        </div>
                    </div>
                </div>
                :
                <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5 }}>
                        <div>
                            <div style={{ borderRadius: 100, width: 100, height: 100, backgroundColor: "#ddd" }}></div>
                        </div>
                        <div style={{ width: 250 }}>
                            <div style={{ fontSize: 18, fontWeight: 600 }}>Full Name</div>
                            <div style={{ fontSize: 15, display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center", gap: 10 }}>
                                <span>Gender</span>
                                <span style={{ width: 5, height: 5, backgroundColor: "#ddd", borderRadius: 100 }}></span>
                                <span>Age</span>
                            </div>
                        </div>
                        <div style={{ width: 250 }}>
                            <div>00000000000</div>
                            <div>00000000000@gmail.com</div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setEditPersonalInfo(false)} style={{ borderRadius: 5, border: "1px solid #ddd", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold" }}>Cancel</button>
                    </div>
                </div>
        } */}
            <div className='hidescroll' style={{ padding: 10, height: "88vh", borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", gap: 10, overflow: "auto" }}>
                <div style={{ width: "80%" }}>
                    <div style={{ fontSize: 20, fontWeight: "bolder" }}>Edit Patient Information</div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", width: "80%" }}>
                    <div style={{ width: "100%" }}>
                        <div>First Name</div>
                        <div style={{ padding: 5 }}>
                            <input name='firstname' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.firstname} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <div>Middle Name</div>
                        <div style={{ padding: 5 }}>
                            <input name='middlename' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.middlename} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <div>Last Name</div>
                        <div style={{ padding: 5 }}>
                            <input name='lastname' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.lastname} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "80%" }}>
                    <div style={{ width: "50%" }}>
                        <div>Mobile Number</div>
                        <div style={{ padding: 5 }}>
                            <input name='contact' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.contact} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div>Email Address</div>
                        <div style={{ padding: 5 }}>
                            <input name='email_address' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.email_address} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "80%" }}>
                    <div style={{ width: "50%" }}>
                        <div>Suffix Name <span style={{ color: "#878787" }}>(optional)</span></div>
                        <div style={{ padding: 5 }}>
                            <input name='suffix' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.suffix} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "80%" }}>
                    <div style={{ width: "100%" }}>
                        <div>Address</div>
                        <div style={{ padding: 5 }}>
                            <textarea name='address' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.address} style={{ resize: "none", padding: 5, height: 100, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "80%" }}>
                    <div style={{ width: "33%" }}>
                        <div>Birthdate</div>
                        <div style={{ padding: 5 }}>
                            <input type='date' name='birthday' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.birthday} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div style={{ width: "33%" }}>
                        <div>Civil Status</div>
                        <div style={{ padding: 5 }}>
                            <select name='civil_status' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform?.civil_status} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }}>
                                <option value="">...</option>
                                <option value={"single"}>Single</option>
                                <option value={"married"}>Married</option>
                                <option value={"separated"}>Separated</option>
                                <option value={"widowed"}>Widowed</option>
                            </select>
                            {/* <input name='civil_status' onChange={(e)=>setInputForm({...inputform, [e.target.name]: e.target.value})} value={inputform?.civil_status} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} /> */}
                        </div>
                    </div>
                </div>
                <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "80%" }}>
                    <div style={{ width: "33%" }}>
                        <div>Gender</div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                                <input name='gender' type='radio' checked={inputform?.gender == 'male' ? true : false} value={'male'} onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 20, width: 20 }} />
                                Male
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                                <input name='gender' type='radio' checked={inputform?.gender == 'female' ? true : false} value={'female'} onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ height: 20, width: 20 }} />
                                Female
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>
            <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 18, fontWeight: "bold" }}><i className='pi pi-user' /> Personal Info</div>
                    <div style={{display:"flex", gap:10}}>
                    <button onClick={()=>setEditPersonalInfo(true)} style={{ borderRadius: 5, border: "1px solid #ddd", width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold" }}>Save</button>
                    <button onClick={() => setEditPersonalInfo(false)} style={{ borderRadius: 5, border: "1px solid #ddd", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold" }}>Cancel</button>
                </div>
                </div>
            </div>
            <div style={{ padding: 10, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}> */}
                <div style={{ padding: 10, width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <div>Occupation</div>
                        <div style={{ padding: 5 }}>
                            <input name='occupation' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.occupation} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Known Chronic Diseases</div>
                        <div style={{ padding: 5 }}>
                            <input name='chronic_diseases' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.chronic_diseases} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                    <div>
                        <div>Known Allergies</div>
                        <div style={{ padding: 5 }}>
                            <input name='allergies' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.allergies} style={{ padding: 5, height: 40, width: "100%", borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div>
                {/* </div>
            <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10, width: "100%" }}> */}
                {/* <div style={{ padding: 10, width: "80%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap:20 }}>
                        <div>
                            <div style={{ fontSize: 18 }}>Pregnancy Status</div>
                            <div>is the patient pregnant</div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                                    <input name='pregnancy' value={"Yes"} type='radio' style={{ height: 20, width: 20 }} /> Yes
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30, gap: 10 }}>
                                    <input name='pregnancy' value={"No"} type='radio' style={{ height: 20, width: 20 }} /> No
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 18 }}>if Pediatric, Geriatric, or</div>
                            <div>PWD</div>
                        </div>
                    </div> */}
                {/* <div style={{ padding: 10, width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{width:500}}>
                        <div style={{ fontSize: 18 }}>Parent or Guardian's</div>
                        <div>Name</div>
                        <div style={{width:500}}>
                            <input style={{ width:500, padding: 5, height: 40, borderRadius: 5, border: "1px solid #ddd" }} />
                        </div>
                    </div>
                </div> */}
            </div>
            <div style={{ padding: 10, borderBottom: "2px solid #ddd", backgroundColor: "#fff" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 18, fontWeight: "bold" }}>
                        {
                            position != 'cashier' ?
                            <DeleteBtn onclickedpatient={onclickedpatient} onClickedPatient={onClickedPatient} onClickedButton={onClickedButton} loadpatient={loadpatient} />
                            : null
                        }
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <button onClick={() => onSubmit()} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "blue", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Save</button>
                        <button onClick={() => { onClickedButton('PatientInfoPanel'); setGenderSelect('') }} style={{ borderRadius: 5, border: "3px solid #fff", width: 120, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditPatientForm;
