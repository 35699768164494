import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../ENV';
import PrescriptionPDFViewer from '../extra/PrescriptionPDFViewer';
import MedicationCertPDFViewer from '../extra/MedicationCertPDFViewer';
import LabRequestFormView from '../extra/LabRequestFormView';

const ConsultationInfront = ({ onclickedpatient, setMedicalRecordButton }) => {

    const [trxlist, setTrxList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("transaction_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data);
            setTrxList(res.data)
        })
    }, [ENV])


    const consultaionlist = trxlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    // console.log(consultaionlist[0].lab_request)

    if (consultaionlist.length > 0) {
        return (
            <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: 18, fontWeight: 600 }}>Consultations</div>
                        <div style={{ fontSize: 18, fontWeight: 500 }}>
                            <button onClick={() => setMedicalRecordButton('Consultation_history')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                                <span>View History</span>
                                <span className='pi pi-arrow-right' />
                            </button>
                        </div>
                    </div>
                    <div>
                        <div><i className='pi pi-user' /> <b>{consultaionlist[0]?.fullname}</b></div>
                        <div><i className='pi pi-calendar' /> {consultaionlist[0]?.date_time}</div>
                    </div>
                </div>
                <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                    <div style={{ paddingBottom: 5, fontWeight: "bolder" }}>Issued Medical Documents</div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", alignItems: "center", gap: 5 }}>
                        {
                            consultaionlist[0]?.prescription.length > 0 ?
                                <PrescriptionPDFViewer item={consultaionlist[0]} />
                                : null
                        } 
                        {
                            consultaionlist[0]?.med_cert.length > 0 ?
                                <MedicationCertPDFViewer item={consultaionlist[0]} />
                                : null
                        }
                        {
                            Object.keys(consultaionlist[0]?.lab_request).length>0 ?
                                <LabRequestFormView item={consultaionlist[0]} />
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
    return (<></>)
}

export default ConsultationInfront;
