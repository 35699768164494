import React from 'react';

const VitalBox = ({ item }) => {
    return (

        <div style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
            <div style={{ padding: 10, borderBottom: "1px solid #ddd" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 18, fontWeight: 600 }}>Vital</div>
                    {/* <div style={{ fontSize: 18, fontWeight: 500 }}>
                    <button onClick={() => setMedicalRecordButton('Vital_history_list')} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, alignItems: "center", border: "none", backgroundColor: "#fff" }}>
                        <span>View History</span>
                        <span className='pi pi-arrow-right' />
                    </button>
                </div> */}
                </div>
                <div>
                    <div><i className='pi pi-calendar' />{item?.date_time}</div>
                </div>
            </div>
            <div style={{ padding: 10 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Weight</div>
                        <div>{item?.weight}</div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Height</div>
                        <div>{item?.height}</div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Blood Type</div>
                        <div>{item?.blood_type}</div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Temperature</div>
                        <div>{item?.tepm}</div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Systolic</div>
                        <div>{item?.systolic}</div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Diastolic</div>
                        <div>{item?.diastolic}</div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Heart Rate</div>
                        <div>{item?.heart_rate}</div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 15, fontWeight: 600 }}>Respiration Rate</div>
                        <div>{item?.respiration_rate}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VitalBox;
