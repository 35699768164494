import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ENV from '../ENV';
import PrescriptionsPreview from './PrescriptionsPreview';
import MedicalCertificatePreview from './MedicalCertificatePreview';
import AppContext from '../context/AppContext';
import io from "socket.io-client";
import { useMemo } from 'react';
import swal from 'sweetalert';


const ConsultationBillingForm = ({ onclickedpatient, onConsultationButton }) => {
    // const socket = io.connect("http://192.168.1.11:3001");

    // alert(ENV.socketAPI)

    if (localStorage.getItem('PrescriptionList')) {
        var prescriptlist = localStorage.getItem('PrescriptionList');
        // console.log(1)
    } else {
        var prescriptlist = JSON.stringify([]);
        // console.log(2)
    }

    if (localStorage.getItem('medcert')) {
        var medcert = localStorage.getItem('medcert');
        // console.log(1)
    } else {
        var medcert = JSON.stringify([]);
        // console.log(2)
    }

    const { state, dispatch } = useContext(AppContext);

    const [medCertData, setMedCertData] = useState(JSON.parse(medcert))
    const medcertdata = medCertData.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    const [prescriptionlist, setPrescriptionList] = useState(JSON.parse(prescriptlist))
    const prescriptiondata = prescriptionlist.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });




    if (localStorage.getItem('LabRequestCheckBox')) {
        var LabRequestCheckBox = JSON.parse(localStorage.getItem('LabRequestCheckBox'));
        // console.log(1)
    } else {
        var LabRequestCheckBox = {};
        // console.log(2)
    }

    const [method, setMethod] = useState('Input Amount')
    const [amount, setAmount] = useState(0)
    const [error, setError] = useState({ amountInpunt: 0 })
    const [isloading, setisLoading] = useState(false)
    const [inputcheck, setInputCheck] = useState(false)

    console.log(inputcheck)


    // useEffect(async()=> {    
    //     socket.emit("clinic_data", 123)    
    //     await socket.emit("clinic_data", {room: 123, message: 'dsfsdfsdf'})
    //     socket.on("receive_data", (data) => {
    //         alert(data+' => dfsdfs')
    //     })
    // }, [socket])

    const onSubmitPayment = async () => {

        // console.log(medcertdata)
        // socket.emit("clinic_room", { room: 123 })
        // socket.emit("clinic_data", { room: 123, data: amount })
        // socket.on("receive_data", (data) => {
        //     alert(data)
        // })

        // return
        // formData.append("medCertData", JSON.stringify({
        //     "complaint": JSON.parse(localStorage.getItem("medcert")).complaint,
        //     "diagnosis": JSON.parse(localStorage.getItem("medcert")).diagnosis,
        //     "recommendation": JSON.parse(localStorage.getItem("medcert")).recommendation
        // }));

        // socket.emit("send_data", {room: 123, data: res.data})
        // alert(onclickedpatient.patient_id)
        // socket.emit("send_data", {room: 123, data: amount})
        // return;
        if (method == 'Input Amount') {
            if (amount!=0) {
                setisLoading(true)
                var formData = new FormData();
                formData.append("save_consultation_data", 1);
                formData.append("patient_id", onclickedpatient.patient_id);
                formData.append("age", onclickedpatient.age);
                formData.append("method", method);
                formData.append("amount", method == 'Input Amount' ? amount : 0);
                formData.append("medCertData", JSON.stringify(medcertdata));
                formData.append("prescription", JSON.stringify(prescriptiondata));
                formData.append("lab_request", JSON.stringify(LabRequestCheckBox));
                formData.append("status", inputcheck? "paid" : "");
                formData.append("receiveby", sessionStorage.getItem('username'));
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    if (inputcheck) {
                        swal({
                            title: "Done!",
                            text: "Successfully Completed",
                            icon: "success",
                            timer: 1000,
                            button: false,
                        }).then(async() => {
                            localStorage.setItem('PrescriptionList', JSON.stringify([]))
                            localStorage.setItem('medcert', JSON.stringify([]))
                            localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
                            onConsultationButton('')
                            window.location.assign("/")
                        });
                    } else {
                        swal({
                            title: "Done!",
                            text: "Successfully Submitted",
                            icon: "success",
                            timer: 1000,
                            button: false,
                        }).then(async() => {
                            localStorage.setItem('PrescriptionList', JSON.stringify([]))
                            localStorage.setItem('medcert', JSON.stringify([]))
                            localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
                            onConsultationButton('')
                            window.location.assign("/")
                        });
                    }
                })
            } else {
                setError({amountInpunt: 1})
                return false
            }
        } else {
            setisLoading(true)
            var formData = new FormData();
            formData.append("save_consultation_data", 1);
            formData.append("patient_id", onclickedpatient.patient_id);
            formData.append("age", onclickedpatient.age);
            formData.append("method", method);
            formData.append("amount", method == 'Input Amount' ? amount : 0);
            formData.append("medCertData", JSON.stringify(medcertdata));
            formData.append("prescription", JSON.stringify(prescriptiondata));
            formData.append("lab_request", JSON.stringify(LabRequestCheckBox));
            formData.append("status", inputcheck? "paid" : "");
            formData.append("receiveby", sessionStorage.getItem('username'));
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                if (inputcheck) {
                    swal({
                        title: "Done!",
                        text: "Successfully Completed",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(async() => {
                        localStorage.setItem('PrescriptionList', JSON.stringify([]))
                        localStorage.setItem('medcert', JSON.stringify([]))
                        localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
                        onConsultationButton('')
                        window.location.assign("/")
                    });
                } else {
                    swal({
                        title: "Done!",
                        text: "Successfully Submitted",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(async() => {
                        localStorage.setItem('PrescriptionList', JSON.stringify([]))
                        localStorage.setItem('medcert', JSON.stringify([]))
                        localStorage.setItem('LabRequestCheckBox', JSON.stringify({}))
                        onConsultationButton('')
                        window.location.assign("/")
                    });
                }
            })
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>
            <div style={{ padding: 5, width: "100%" }}>
                {/* <div>
                    <button onClick={() => onConsultationButton('')}>Back</button>
                </div> */}
                {/* <span style={{ fontWeight: 600, fontSize: 25 }}>Services Rendered</span> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
                {
                    prescriptiondata.length > 0 ?
                        <PrescriptionsPreview />
                        : null
                }
                {
                    medcertdata.length > 0 ?
                        <MedicalCertificatePreview />
                        : null
                }
                {
                    (LabRequestCheckBox?.blood_type == true ||
                        LabRequestCheckBox?.cbc == true ||
                        LabRequestCheckBox?.platelet == true ||
                        LabRequestCheckBox?.serum == true ||
                        LabRequestCheckBox?.urinalysis == true ||
                        LabRequestCheckBox?.stool_exam == true ||
                        LabRequestCheckBox?.xray == true ||
                        LabRequestCheckBox?.ultrasound == true ||
                        LabRequestCheckBox?.ctscan == true ||
                        LabRequestCheckBox?.MRI == true ||
                        LabRequestCheckBox?.twelve_leads_ecg == true ||
                        LabRequestCheckBox?.twoD_echo == true ||
                        LabRequestCheckBox?.cardiac_stress_test == true ||
                        LabRequestCheckBox?.carotid_doppler_scan == true ||
                        LabRequestCheckBox?.arterial_duplex_scan == true ||
                        LabRequestCheckBox?.venous_duplex_scan == true) ?
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: 500, height: 50, padding: 10, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                            <span style={{ fontWeight: 600, fontSize: 15 }}>Lab Request Form</span>
                            {/* <span>view</span> */}
                        </div>
                        : null
                }
            </div>
            <div style={{ width: 500, padding: 5 }}>
                <span style={{ fontWeight: 600, fontSize: 20 }}>Payment Breakdown</span>
                <div style={{ width: "100%" }}>
                    {
                        prescriptiondata.length > 0 ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd" }}>
                                <span style={{ fontWeight: 500, fontSize: 15 }}>Prescription</span>
                                {/* <span>0.00</span> */}
                            </div>
                            : null
                    }
                    {
                        medcertdata.length > 0 ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd" }}>
                                <span style={{ fontWeight: 500, fontSize: 15 }}>Medical Certificate</span>
                                {/* <span>0.00</span> */}
                            </div>
                            : null
                    }
                    {
                        (LabRequestCheckBox?.blood_type == true ||
                            LabRequestCheckBox?.cbc == true ||
                            LabRequestCheckBox?.platelet == true ||
                            LabRequestCheckBox?.serum == true ||
                            LabRequestCheckBox?.urinalysis == true ||
                            LabRequestCheckBox?.stool_exam == true ||
                            LabRequestCheckBox?.xray == true ||
                            LabRequestCheckBox?.ultrasound == true ||
                            LabRequestCheckBox?.ctscan == true ||
                            LabRequestCheckBox?.MRI == true ||
                            LabRequestCheckBox?.twelve_leads_ecg == true ||
                            LabRequestCheckBox?.twoD_echo == true ||
                            LabRequestCheckBox?.cardiac_stress_test == true ||
                            LabRequestCheckBox?.carotid_doppler_scan == true ||
                            LabRequestCheckBox?.arterial_duplex_scan == true ||
                            LabRequestCheckBox?.venous_duplex_scan == true) ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd" }}>
                                <span style={{ fontWeight: 500, fontSize: 15 }}>Lab Request Form</span>
                                {/* <span>0.00</span> */}
                            </div>
                            : null
                    }
                    {/* {
                        prescriptiondata.length > 0 ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom:"1px solid #ddd" }}>
                                <span style={{ fontWeight: 500, fontSize: 15 }}>Diagnostic Prescription</span>
                                <span>0.00</span>
                            </div>
                            : null
                    } */}
                </div>
            </div>
            <div>
                <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div>
                        <select onChange={(e) => setMethod(e.target.value)} style={{ width: 150, height: 30, borderRadius: 5, outline: "none", border: "2px solid #fff", boxShadow: "-1px 2px 5px grey" }}>
                            <option value={"Input Amount"}>Input Amount</option>
                            <option value={"Insurance"}>Insurance</option>
                            <option value={"Free"}>Free</option>
                        </select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {
                            method == 'Input Amount' ?
                                <input type='number' onChange={(e) => setAmount(e.target.value)} style={{ width: 150, height: 30, borderRadius: 5, border: error.amountInpunt == 1 ? "1px solid red" : "1px solid #ddd", outline: "none", padding: 5, textAlign: "right" }} />
                                : null
                        }
                        {
                            method == 'Insurance' ?
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30 }}>
                                    <span style={{ fontWeight: 600, fontSize: 15, }}>Insurance</span>
                                </div>
                                : null
                        }
                        {
                            method == 'Free' ?
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30 }}>
                                    <span style={{ fontWeight: 600, fontSize: 15, }}>Free</span>
                                </div>
                                : null
                        }
                    </div>
                    {/* <span style={{fontWeight: 600, fontSize: 15, }}>0.00</span> */}
                </div>
                {/* <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <span style={{ fontWeight: 600, fontSize: 15, }}>Charge</span>
                    <span style={{ fontWeight: 600, fontSize: 15, }}>0.00</span>
                </div> */}
                {
                    method == 'Input Amount' ?
                        <>
                            <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ fontWeight: 600, fontSize: 20, }}>Total Fee</span>
                                <span style={{ fontWeight: 600, fontSize: 20, }}>{amount != 0 ? amount : '0.00'}</span>
                            </div>
                            <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ fontWeight: 600, fontSize: 20, }}></span>
                                <span style={{ fontWeight: "normal", fontSize: 15, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                                    <span>Paid</span>
                                    <input type='checkbox' onChange={(e)=>setInputCheck(e.target.checked)} />
                                </span>
                            </div>
                        </>
                        : null
                }
                {
                    method == 'Insurance' ?
                        <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        </div>
                        : null
                }
                {
                    method == 'Free' ?
                        <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        </div>
                        : null
                }
            </div>
            <div style={{ width: 500, display: "flex", flexDirection: "row", gap: 5 }}>
                <button onClick={() => onConsultationButton('')} style={{ width: 100, height: 40, borderRadius: 5, border: "3px solid #fff", backgroundColor: "orange", color: "#fff", fontWeight: 600, boxShadow: "-1px 2px 5px grey" }}>Back</button>
                {
                    isloading ?
                    <button style={{ width: 200, height: 40, borderRadius: 5, border: "3px solid #fff", backgroundColor: "rgb(136 153 149)", color: "#fff", fontWeight: 600, boxShadow: "-1px 2px 5px grey" }}>Please wait...</button>
                    :
                    <button onClick={() => onSubmitPayment()} style={{ width: 200, height: 40, borderRadius: 5, border: "3px solid #fff", backgroundColor: "#43bea0", color: "#fff", fontWeight: 600, boxShadow: "-1px 2px 5px grey" }}>Submit Billing</button>

                }
                
            </div>
        </div>
    );
}

export default ConsultationBillingForm;
