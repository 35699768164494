import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const ReportTableButton = ({ i, loadData }) => {
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    // swal({
    //     // title: "Invalid!",
    //     text: "Incorrect username and password",
    //     icon: "error",
    //     timer: 2000,
    //     button: false,
    // });


    const [method, setMethod] = useState(i.method ?? 'Input Amount')
    const [amount, setAmount] = useState(0)
    const [error, setError] = useState({ amountInpunt: 0 })


    const DeleteData = async (i) => {
        console.log(i)
        var formData = new FormData();
        formData.append("delete_record", 1);
        formData.append("record_id", i.id);
        formData.append("delete_record_status", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data === 'success') {
                swal({
                    text: "Successfully Deleted",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(()=>{
                    setOpenDelete(false)
                    loadData()
                });
            } else {

            }
        })
            .catch((err) => {
                console.log(err);
            });
    }



    const UpdateData = async (i) => {
        var formData = new FormData();

        if (method === 'Input Amount') {
            if (amount !== 0) {
                setError({ amountInpunt: 0 })
                formData.append("update_record", 1);
                formData.append("record_id", i.id);
                formData.append("method", method);
                formData.append("amount", amount);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    if (res.data === 'success') {
                        swal({
                            // title: "Invalid!",
                            text: "Successfully Updated",
                            icon: "success",
                            timer: 2000,
                            button: false,
                        }).then(()=>{
                            setOpenEdit(false)
                            loadData()
                        });
                    } else {

                    }
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setError({ amountInpunt: 1 })
                return false
            }
        } else {
            formData.append("update_record", 1);
            formData.append("record_id", i.id);
            formData.append("method", method);
            formData.append("amount", amount);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                if (res.data === 'success') {
                    swal({
                        // title: "Invalid!",
                        text: "Successfully Updated",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    }).then(()=>{
                        setOpenEdit(false)
                        loadData()
                    });
                } else {

                }
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    
    return (
        <>
            <Dialog closable={false} closeOnEscape={true} header={i.status === 'paid' || i.status === 'Free' || i.status === 'Insurance' ? "Show Payment Record" : "Edit Payment Record"}
                footer={
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        {
                            i.status !== 'paid' ?
                            <button className='btn btn-success btn-sm' onClick={() => UpdateData(i)}>Update</button>
                            :null
                        }
                        <button className='btn btn-danger btn-sm' onClick={() => { setOpenEdit(false); setMethod(i.method) }}>
                            {
                                i.status === 'paid' ?
                                <>Close</>
                                :
                                <>Cancel</>
                            }
                        </button>
                    </div>
                } visible={openEdit} style={{ width: 600 }} onHide={() => setOpenEdit(false)}>
                <div>
                    <div>
                        {
                            i.status !== 'paid' ?
                            <>
                                    <span>Payment Details</span>
                                    <div>
                                        <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                            <div>
                                                <select onChange={(e) => setMethod(e.target.value)} defaultValue={method} style={{ width: 150, height: 30, borderRadius: 5, outline: "none", border: "2px solid #fff", boxShadow: "-1px 2px 5px grey" }}>
                                                    <option value={"Input Amount"}>Input Amount</option>
                                                    <option value={"Insurance"}>Insurance</option>
                                                    <option value={"Free"}>Free</option>
                                                </select>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                {
                                                    method === 'Input Amount' ?
                                                        <input type='number' onChange={(e) => setAmount(e.target.value)} style={{ width: 150, height: 30, borderRadius: 5, border: error.amountInpunt === 1 ? "1px solid red" : "1px solid #ddd", outline: "none", padding: 5, textAlign: "right" }} />
                                                        : null
                                                }
                                                {
                                                    method === 'Insurance' ?
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30 }}>
                                                            <span style={{ fontWeight: 600, fontSize: 15, }}>Insurance</span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    method === 'Free' ?
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 30 }}>
                                                            <span style={{ fontWeight: 600, fontSize: 15, }}>Free</span>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            method === 'Input Amount' ?
                                                <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <span style={{ fontWeight: 600, fontSize: 20, }}>Total Fee</span>
                                                    <span style={{ fontWeight: 600, fontSize: 20, }}>{amount !== 0 ? amount : i.amount}</span>
                                                </div>
                                                : null
                                        }
                                        {
                                            method === 'Insurance' ?
                                                <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                </div>
                                                : null
                                        }
                                        {
                                            method === 'Free' ?
                                                <div style={{ width: 500, padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                </div>
                                                : null
                                        }
                                    </div>
                            </>
                            :
                            <>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 5}}>
                                    <img src={require('../paid-stamp-paid-grunge-stamp-sign-icon-editable-vector-illustration-isolated-white-background-123572302.jpg')} style={{width: "50%", height: "50%", opacity: 0.25}} />
                                    {/* <span style={{fontSize:30, fontWeight: 800, color: "green"}}>PAID</span> */}
                                    
                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 5, position:"absolute", zIndex:1}}>
                                        <span style={{fontSize:40, fontWeight: 800}}>{i.amount}</span>
                                        <span style={{fontSize:18, fontWeight: 600}}>{i.date_paid}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>




            </Dialog>

            <Dialog header="Are you sure?" visible={openDelete} style={{ width: '30vw', padding: 5 }} onHide={() => setOpenDelete(false)}>
                {/* <div style={{ paddingBottom: 20, textAlign: "center", fontWeight: 600, fontSize: 20, padding: 10 }}>
                    <span>
                        Name: <b>{i.fullname}</b>
                    </span>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <button onClick={() => DeleteData(i)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "green", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>Yes</button>
                    <button onClick={() => setOpenDelete(false)} style={{ borderRadius: 5, border: "3px solid #fff", width: 150, height: 35, backgroundColor: "red", color: "#fff", fontWeight: "bold", boxShadow: "-1px 2px 5px grey" }}>No</button>
                </div>
            </Dialog>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>
                <button onClick={() => setOpenEdit(true)} className='btn btn-info btn-sm'>
                    {
                        i.method === 'Input Amount' ?
                            <>
                                {
                                    i.status === 'paid' ?
                                        <>View</>
                                        :
                                        <>Edit</>
                                }
                            </>
                            :
                            <>
                                {
                                    i.method === 'Insurance' ?
                                        <>View</>
                                        : null
                                }
                                {
                                    i.method === 'Free' ?
                                        <>View</>
                                        : null
                                }
                            </>
                    }
                </button>
                <button onClick={() => setOpenDelete(true)} className='btn btn-danger btn-sm'>Delete</button>
            </div>
        </>
    );
}

export default ReportTableButton;
